import { Outlet } from "react-router-dom";
import Aux from "../auxiliary";
import PublicMobileSideMenu from "../../components/navigation/access-public/PublicMobileSideMenu";
import { useAppContext } from "../../context/AppContext";
import ScrollToTop from "../../components/navigation/ScrollToTop";

const LayoutClient = () => {
  const { isMobileDevice } = useAppContext();

  return (
    <Aux>
      <ScrollToTop />
      {/* <Header /> */}
      {/* <MenuToggle /> */}
      {isMobileDevice && <PublicMobileSideMenu />}
      <Outlet />

      {/* <Footer /> */}
    </Aux>
  );
};

export default LayoutClient;
