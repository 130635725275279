import classes from "./admin-classes.module.css";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SaveCancelButtons from "../../components/save-cancel-buttons/SaveCancelButtons";
import Checkbox from "../../components/ui/checkbox/Checkbox";
import Select, { SelectOptionType } from "../../components/ui/select/Select";
import {
  AdminPaths,
  BasicComponentType,
  BasicComponentTypes,
  CookiePolicyPageIdentifier,
  HomePageIdentifier,
  LayoutSectionTypes,
  LayoutStructureType,
  NewPageParameter,
  PageDataType,
  PageStructure,
  PageType,
  WrapperComponentType,
} from "../../firebase/types";
import Aux from "../../hoc/auxiliary";
import PopupSpinner from "../../hoc/popup-spinner/popup-spinner";
import { useAppContext } from "../../context/AppContext";
import { dateFormatToDayMonthYear } from "../../helper-functions/date-time";
import Button, { ButtonClassTypes } from "../../components/ui/button/Button";
import {
  flagIncompletePageTranslations,
  getSelectOptionsForAllComponents,
} from "../../helper-functions/general";
import update from "immutability-helper";
import PageStructureListElement from "../../components/admin-pages/PageStructureListElement";
import LayoutMockup from "../../components/layout-mockup/LayoutMockup";

export const PAGE_CONTENT_KEY = "content_";

function Pages() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { p } = useParams();
  const {
    get_page_by_path,
    create_page,
    update_page,
    delete_page,
    isMobileDevice,
    basicComponents,
    wrapperComponents,
  } = useAppContext();
  const web_language = useAppContext().language;

  const [showSpinner, setShowSpinner] = useState("");
  const [spinnerTitle, setSpinnerTitle] = useState("");

  const [page, setPage] = useState<PageType>();
  const [linkLabel, setLinkLabel] = useState("");
  const [path, setPath] = useState("");
  const [pathError, setPathError] = useState("");
  const [content, setContent] = useState<any>({});
  const [pageStructure, setPageStructure] = useState<PageStructure[]>([]);
  const [status, setStatus] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [allComponents, setAllComponents] = useState<any[]>([]);

  const [layoutStructure, setLayoutStructure] = useState<LayoutStructureType>(
    {}
  );

  const languages: SelectOptionType[] = t("languages", {
    returnObjects: true,
  });
  const [language, setLanguage] = useState("");

  const changeLanguage = (opt: any) => {
    setLanguage(opt.value);
  };

  useEffect(() => {
    if (language === "") {
      setLanguage(web_language);
    }
  }, [language, web_language]);

  useEffect(() => {
    const getWantedPage = (p: string) => {
      try {
        const data = get_page_by_path(p);
        if (data && data.id) {
          setPage(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (p === NewPageParameter) return;
    getWantedPage(p ? p : "");
  }, [p, get_page_by_path]);

  useEffect(() => {
    const loadPageDataByLanguage = (page: PageType, lang: string) => {
      if (!page) return;
      const found = page
        ? page.data.find((d: PageDataType) => d.language === lang)
        : null;

      if (found && page && (page.status === false || page.status === true)) {
        setLinkLabel(found.linkLabel);
        setPath(page.path);
        setStatus(page.status);
        setContent(found.content ? found.content : []);
        setPageStructure(page.structure ? page.structure : []);
        setShowButtons(false);
        setLayoutStructure(page.layoutStructure ? page.layoutStructure : {});
      }
    };

    if (page && language) {
      loadPageDataByLanguage(page, language);
    }
  }, [page, language]);

  const savePage = async () => {
    if (!p) {
      // console.log("Save page -> No page path.");
      return;
    }

    if (!path) {
      setPathError(t("enter_valid_details"));
      return;
    }

    setShowSpinner("spinner");
    setSpinnerTitle(t("saving_page") + linkLabel);

    let pageData: PageDataType[] = languages.map((lang: any) => ({
      linkLabel: "",
      content: {},
      language: lang.value,
    }));
    if (page && page.data) {
      pageData = page.data;
    }

    // aux - fix for old pages - 15 Sept 2022:
    const foundDe = pageData.find((el) => el.language === "de");
    if (!foundDe)
      pageData.push({ linkLabel: "link 4 de", content: {}, language: "de" });

    const foundEs = pageData.find((el) => el.language === "es");
    if (!foundEs)
      pageData.push({ linkLabel: "link 4 es", content: {}, language: "es" });
    // end aux

    const data = pageData.map((d: PageDataType) => {
      if (d.language === language) {
        return { linkLabel, content, language };
      } else return d;
    });
    let request = {
      ...page,
      path,
      status,
      structure: pageStructure,
      updatedAt: Date.now(),
      data,
      layoutStructure,
    };
    if (p === NewPageParameter && (!page || !page.id)) {
      try {
        request.createdAt = Date.now();
        await create_page(request);
      } catch (error) {
        console.log(error);
      }
    }
    if (page && page.id && p === page.path) {
      try {
        await update_page(request);
      } catch (error) {
        console.log(error);
      }
    }
    navigate(AdminPaths.ROUTES);
  };
  const cancelSave = async () => {
    if (page) {
      setPage({ ...page, updatedAt: Date.now() });
    } else {
      navigate(-1);
    }
  };
  const deletePage = async (id?: string) => {
    setShowSpinner("spinner");
    setSpinnerTitle(t(""));
    try {
      await delete_page(id);
      navigate(AdminPaths.ROUTES);
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteDetails = () => {
    if (!page) return <p></p>;

    return (
      <div className={classes.PageDeleteDetailsOuter}>
        {isMobileDevice ? (
          <Aux>
            <div className="PaddingB">
              {page && page.createdAt && (
                <p>
                  {t("created_at")}:{" "}
                  {dateFormatToDayMonthYear(page.createdAt, t)}
                </p>
              )}
              {page && page.createdAt && (
                <p>
                  {t("updated_at")}:{" "}
                  {dateFormatToDayMonthYear(page.updatedAt, t)}
                </p>
              )}
            </div>
            <Button
              className={ButtonClassTypes.RED}
              handleClick={() => {
                deletePage(page ? page.id : "");
              }}
              fullWidth={true}
            >
              {t("delete_page")}
            </Button>
          </Aux>
        ) : (
          <Aux>
            <Button
              className={ButtonClassTypes.RED}
              handleClick={() => {
                deletePage(page ? page.id : "");
              }}
              fullWidth={true}
            >
              {t("delete_page")}
            </Button>
            <div className="PaddingTSmall">
              {page && page.createdAt && (
                <p>
                  {t("created_at")}:{" "}
                  {dateFormatToDayMonthYear(page.createdAt, t)}
                </p>
              )}
              {page && page.createdAt && (
                <p>
                  {t("updated_at")}:{" "}
                  {dateFormatToDayMonthYear(page.updatedAt, t)}
                </p>
              )}
            </div>
          </Aux>
        )}
      </div>
    );
  };

  useEffect(() => {
    let opts: any[] = [
      { label: `- ${t("add_component")} -`, value: -1 },
      { label: t("add_text_editor"), value: "textEditor" },
    ];
    opts = [
      ...opts,
      ...getSelectOptionsForAllComponents(
        basicComponents,
        wrapperComponents,
        t
      ),
    ];
    setAllComponents(opts);
  }, [basicComponents, wrapperComponents, t]);

  const findComponentByID = (
    pool: (BasicComponentType | WrapperComponentType)[],
    result: PageStructure[],
    ID: string
  ) => {
    const timenow = Date.now();
    const foundCard = pool.find((el: any) => el.id === ID);
    if (foundCard) {
      let newElement: PageStructure = { order: pageStructure.length, id: "" };
      newElement.id = foundCard.id + "_" + timenow;
      result.push(newElement);
    }
    return result;
  };
  const addNewComponent = (opt: any) => {
    const timenow = Date.now();
    let newPageStructure: any[] = pageStructure ? [...pageStructure] : [];
    let newElement: PageStructure = { order: pageStructure.length, id: "" };

    if (opt.value === "textEditor") {
      newElement.id = `${PAGE_CONTENT_KEY}${timenow}`;
      newPageStructure.push(newElement);
    }

    if (
      opt.type === BasicComponentTypes.CARD ||
      opt.type === BasicComponentTypes.FAQ ||
      opt.type === BasicComponentTypes.SLIDESHOW
    ) {
      newPageStructure = [
        ...findComponentByID(basicComponents, newPageStructure, opt.value),
      ];
    }

    if (opt.type === "hoc") {
      newPageStructure = [
        ...findComponentByID(wrapperComponents, newPageStructure, opt.value),
      ];
    }

    setPageStructure(newPageStructure);

    setShowButtons(true);
  };

  const removeComponent = useCallback(
    (ev: React.BaseSyntheticEvent, structId: string) => {
      ev.stopPropagation();
      setShowButtons(true);
      const newPageStructure = pageStructure.filter(
        (pS: any) => pS.id !== structId
      );
      if (structId.indexOf(PAGE_CONTENT_KEY) === 0) {
        let newContent = { ...content };
        delete newContent[structId];
        setContent(newContent);
      }
      setPageStructure(newPageStructure);
    },
    [content, pageStructure]
  );
  const handleTextEditorBlur = useCallback(
    (value: string, id: string) => {
      let newContent = { ...content };
      newContent[id] = value;
      setContent(newContent);
      setShowButtons(true);
    },
    [content]
  );
  const handleSaveComponentStyles = useCallback(
    (structElem: PageStructure) => {
      const newPS = pageStructure.map((pS: PageStructure) =>
        pS.id === structElem.id ? { ...structElem } : { ...pS }
      );
      setPageStructure(newPS);
      setShowButtons(true);
    },
    [pageStructure]
  );

  const handleChangeRelativePath = (ev: React.BaseSyntheticEvent) => {
    setPath(ev.target.value);
    setPath(ev.target.value);
    setPathError(ev.target.value ? "" : t("enter_valid_details"));
    setShowButtons(true);

    if (ev.target.value === HomePageIdentifier) {
      const response = get_page_by_path(ev.target.value);
      if (response && ((page && response.id !== page.id) || !page)) {
        setPathError(t("home_page_already_set"));
      } else {
        setStatus(true);
      }
    }
    if (ev.target.value === CookiePolicyPageIdentifier) {
      const response = get_page_by_path(ev.target.value);
      if (response && ((page && response.id !== page.id) || !page)) {
        setPathError(t("cookies_policy_page_already_set"));
      } else {
        setStatus(true);
      }
    }
  };

  const moveElement = useCallback((dragIndex: number, hoverIndex: number) => {
    setPageStructure((prev: PageStructure[]) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex] as PageStructure],
        ],
      })
    );
    setShowButtons(true);
  }, []);
  const renderPageElement = useCallback(
    (pS: PageStructure, index: number) => {
      return (
        <PageStructureListElement
          content={content}
          handleTextEditorBlur={handleTextEditorBlur}
          handleSaveComponentStyles={handleSaveComponentStyles}
          removeComponent={removeComponent}
          basicComponents={basicComponents}
          wrapperComponents={wrapperComponents}
          item={pS}
          index={index}
          moveElement={moveElement}
          key={pS.id} // very important
        />
      );
    },
    [
      basicComponents,
      wrapperComponents,
      content,
      handleSaveComponentStyles,
      handleTextEditorBlur,
      removeComponent,
      moveElement,
    ]
  );

  const handleChangeLayoutStructure = useCallback(
    (opt: { label: string; value: string }, type: LayoutSectionTypes) => {
      const handle_change = async (opt: any, type: LayoutSectionTypes) => {
        if (opt.value === "0") {
          navigate(`${AdminPaths.LAYOUT}/${type}`);
          return;
        }

        let newLS: LayoutStructureType = layoutStructure
          ? { ...layoutStructure }
          : {};

        switch (type) {
          case LayoutSectionTypes.HEADER:
            newLS.header = opt.value;
            break;
          case LayoutSectionTypes.CONTENT_LEFT:
            newLS.content_left = opt.value;
            break;
          case LayoutSectionTypes.CONTENT_RIGHT:
            newLS.content_right = opt.value;
            break;
          case LayoutSectionTypes.FOOTER:
            newLS.footer = opt.value;
            break;

          default:
            break;
        }

        setLayoutStructure(newLS);
      };

      handle_change(opt, type);
      setShowButtons(true);
    },
    [layoutStructure, navigate]
  );

  return (
    <div style={{ paddingBottom: isMobileDevice ? "100px" : "240px" }}>
      {showSpinner === "spinner" && (
        <PopupSpinner
          hideHeader={true}
          actionClose={() => setShowSpinner("")}
          showPopup={showSpinner}
          title={spinnerTitle}
        />
      )}
      <div className={classes.PagesPageTitleDetailsOuter}>
        <div>
          <p className="RouteTitle">
            {t("page")}: {linkLabel}{" "}
            <span className="Explanation">({t(language)})</span>
          </p>
          <p className="Explanation">
            {t("you_should_ensure_to_set_up_translations")}
          </p>
          <p className="Explanation">{t("unset_language_details")}</p>
          {page && (
            <p className="ErrorParagraph Small">
              {flagIncompletePageTranslations(page, t)}
            </p>
          )}
        </div>
        {!isMobileDevice && <DeleteDetails />}
      </div>
      {showButtons && pathError === "" ? (
        <Aux>
          <div className="FullWidthBottomBorder"></div>
          <SaveCancelButtons
            handleClickSave={savePage}
            handleClickCancel={cancelSave}
          />
        </Aux>
      ) : null}
      <div className="FullWidthBottomBorder"></div>
      {(HomePageIdentifier === path &&
        pathError !== t("home_page_already_set")) ||
      (CookiePolicyPageIdentifier === path &&
        pathError !== t("cookie_policy_page_already_set")) ? (
        <Aux>
          <Checkbox
            handleChange={() => {}}
            label={t("link_and_page_are_visible")}
            checked={true}
            disabled={true}
            className="PaddingBSmall"
          />
          <p className="Explanation PaddingTopSmall">{t("status_page_expl")}</p>
        </Aux>
      ) : (
        <Aux>
          <Checkbox
            handleChange={(ev: Event, data: { value: boolean }) => {
              setStatus(data?.value);
              setShowButtons(true);
            }}
            label={t("link_and_page_are_visible")}
            checked={status}
            className="PaddingBSmall"
          />
          <p className="Explanation PaddingTopSmall">
            {t("status_applies_to_all")}
          </p>
        </Aux>
      )}
      <br />
      <div className={classes.SettingsGroupRow}>
        <div>
          <p className="PaddingBSmall">{t("relative_path")}:</p>
          <input
            placeholder="..."
            type="text"
            value={path}
            onChange={handleChangeRelativePath}
            style={{ borderColor: pathError ? "var(--text-error)" : "" }}
          />
          {pathError && (
            <p className="ErrorParagraph PaddingVSmall">{pathError}</p>
          )}
        </div>
      </div>
      <p className="Explanation PaddingVSmall">{t("relative_path_expl_1")}</p>
      <p className="Explanation">{t("home_page_expl_1")}</p>
      <br />

      <p className="PaddingBSmall">{t("select_a_component_to_add_on_page")}:</p>
      <div className={classes.SettingsGroupRow}>
        <Select
          selectOptions={{
            initialValue: -1,
            keepInitialValue: true,
            handleChange: addNewComponent,
            options: allComponents,
            searchable: true,
          }}
        />
      </div>
      <p className="Explanation PaddingVSmall">
        {t("select_a_component_expl_3")}
      </p>
      <p className="Explanation PaddingBSmall">
        {t("select_a_component_expl_1")}
      </p>
      <p className="Explanation">{t("select_a_component_expl_2")}</p>
      <div className="FullWidthBottomBorder"></div>
      <div className={classes.PageSelectLanguage}>
        <span>{t("edit_language")}:</span>
        &nbsp;&nbsp;
        <Select
          selectOptions={{
            initialValue: language,
            handleChange: changeLanguage,
            options: languages,
          }}
        />
      </div>
      <p className="Explanation PaddingTSmall">{t("edit_language_expl_1")}</p>
      <br />
      <div className={classes.SettingsGroupRow}>
        <div>
          <p className="PaddingBSmall">{t("link_label")}:</p>
          <input
            className="DesktopHalfWidth"
            placeholder="..."
            type="text"
            value={linkLabel}
            onChange={(ev) => {
              setLinkLabel(ev.target.value);
              setShowButtons(true);
            }}
          />
        </div>
      </div>
      <br />
      <p className="PaddingB">{t("edit_this_route")}:</p>
      <LayoutMockup
        handleChange={handleChangeLayoutStructure}
        forRoutes={true}
        layoutStructure={layoutStructure}
      />
      <br />

      <p className="RouteTitle">{t("route_main_details")}</p>
      {pageStructure && pageStructure.length ? (
        <ul className={classes.PageContentOuter}>
          {pageStructure.map((el: PageStructure, idx: number) =>
            renderPageElement(el, idx)
          )}
        </ul>
      ) : (
        <p style={{ padding: "14px 5px 3px 5px" }}>{`- ${t(
          "add_component"
        )} -`}</p>
      )}
      {showButtons && pathError === "" ? (
        <Aux>
          <SaveCancelButtons
            handleClickSave={savePage}
            handleClickCancel={cancelSave}
          />
          <div className="FullWidthBottomBorder"></div>
        </Aux>
      ) : null}
      {isMobileDevice && <DeleteDetails />}
    </div>
  );
}

export default Pages;
