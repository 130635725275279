import classes from "../../components/navigation/access-admin/layout-and-header.module.css";
import { Outlet } from "react-router-dom";
import Header from "../../components/navigation/access-admin/Header";
import SideMenu from "../../components/navigation/access-admin/menu/SideMenu";
import Aux from "../auxiliary";
import { motion } from "framer-motion";
import ScrollToTop from "../../components/navigation/ScrollToTop";

const LayoutAdmin = () => {
  return (
    <Aux>
      <ScrollToTop />
      <Header />
      <div className={classes.WebsiteContentAdminOuter}>
        <SideMenu />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={classes.WebsiteContentAdmin}
        >
          <Outlet />
        </motion.div>
      </div>
    </Aux>
  );
};

export default LayoutAdmin;
