import { useTranslation } from "react-i18next";
import classes from "./side-menu-wraper.module.css";

import { useAppContext } from "../../../context/AppContext";
import { sign_out } from "../../../firebase/auth-functions";
import { useNavigate } from "react-router-dom";

import SignOut from "../../../assets/svg/sign_out.svg";
import Aux from "../../../hoc/auxiliary";

import LanguageSelect from "../../language-select/LanguageSelect";
import Backdrop from "./Backdrop";

function SideMenuWraper({
  children,
  isPublic,
}: {
  children: any;
  isPublic?: boolean;
}) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { currentUser, showSideDrawer, isMobileDevice, toggle_side_drawer } =
    useAppContext();

  const goToAuthenticate = () => {
    navigate("/authenticate");
    toggle_side_drawer();
  };
  const signOut = () => {
    sign_out(
      () => {
        navigate("/");
        toggle_side_drawer();
      },
      () => {}
    );
  };

  let attachedClasses = [classes.SideMenuWraper, classes.Close].join(" ");
  if (showSideDrawer) {
    attachedClasses = [classes.SideMenuWraper, classes.Open].join(" ");
  }

  return (
    <Aux>
      <Backdrop />
      <div
        className={isMobileDevice ? attachedClasses : classes.SideMenuWraper}
      >
        {isPublic ? (
          children
        ) : (
          <Aux>
            <div>
              <div className={classes.LanguageSelectOuter}>
                <LanguageSelect />
              </div>
              <nav className={classes.SideMenu}>{children}</nav>
            </div>

            {currentUser ? (
              <div className={classes.SideMenuSubstitute} onClick={signOut}>
                <div className={classes.ImageOuter}>
                  <img src={SignOut} alt={t("user")} />
                </div>
                <p className={classes.MenuLabel}>{t("log_out")}</p>
              </div>
            ) : (
              <div
                className={classes.SideMenuSubstitute}
                onClick={goToAuthenticate}
              >
                <p className={classes.MenuLabel}>{t("sign_in")}</p>
              </div>
            )}
          </Aux>
        )}
      </div>
    </Aux>
  );
}

export default SideMenuWraper;
