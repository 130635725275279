import { useAppContext } from "../../context/AppContext";
import { ReactElement, useMemo } from "react";
import { parseJsonStringifiedToJsonObject } from "../../helper-functions/general";
import FaqListElement from "./FaqListElement";
import {
  BasicComponentDataType,
  BasicComponentType,
} from "../../firebase/types";
import StyledDivOrAux from "../../hoc/StyledDivOrAux";

function FaqComponent({ faq }: { faq: BasicComponentType }): ReactElement {
  const { isMobileDevice } = useAppContext();

  const faqHoverStyle = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? "" : faq.styleListElementHover
    );
  }, [isMobileDevice, faq]);
  const faqStyle = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? faq.styleListElementM : faq.styleListElement
    );
  }, [isMobileDevice, faq]);
  const styleTitle = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? faq.styleTitleM : faq.styleTitle
    );
  }, [isMobileDevice, faq]);
  const styleText = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? faq.styleTextM : faq.styleText
    );
  }, [isMobileDevice, faq]);

  if (!faq) return <div></div>;

  return (
    <StyledDivOrAux
      styleObj={parseJsonStringifiedToJsonObject(
        isMobileDevice ? faq.styleOuterM : faq.styleOuter
      )}
    >
      <StyledDivOrAux
        styleObj={parseJsonStringifiedToJsonObject(
          isMobileDevice ? faq.styleListM : faq.styleList
        )}
      >
        {faq.data &&
          faq.data.map((el: BasicComponentDataType, idx: number) => (
            <FaqListElement
              item={el}
              faqHoverStyle={faqHoverStyle}
              faqStyle={faqStyle}
              key={idx}
              styleTitle={styleTitle}
              styleText={styleText}
            />
          ))}
      </StyledDivOrAux>
    </StyledDivOrAux>
  );
}

export default FaqComponent;
