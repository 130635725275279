import { useTranslation } from "react-i18next";
import {
  PageStructure,
  BasicComponentTypes,
  BasicComponentType,
  WrapperComponentType,
} from "../../firebase/types";
import { PAGE_CONTENT_KEY } from "../../routes/admin/PageById";
import SlideShowComponent from "../slide-show/SlideShowComponent";
import CardsComponent from "../basic-components/CardsComponent";

import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import classes from "../../routes/admin/admin-classes.module.css";
import { motion } from "framer-motion";
import TextEditor from "../text-editor/TextEditor.component";
import useFormJSK from "../../hoc/hooks/useFormJSX";
import Input, { InputThemeOptions } from "../ui/input/Input";
import Select from "../ui/select/Select";
import Button, { ButtonClassTypes } from "../ui/button/Button";
import {
  parseJsonStringifiedToJsonObject,
  parseJsonStringifiedToTextareaJsx,
} from "../../helper-functions/general";
import Aux from "../../hoc/auxiliary";
import FaqComponent from "../basic-components/FaqComponent";
import WrapperComponent from "../wrappers-hoc/WrapperComponent";
import DraggableListElementWrapper from "../draggable-list-element-wrapper/DraggableListElementWrapper";
import DropdownComponent from "../basic-components/DropdownComponent";

interface PSLEProps {
  item: PageStructure;
  index: number;
  moveElement: (dragIndex: number, hoverIndex: number) => void;
  content: any;
  handleSaveComponentStyles: any;
  handleTextEditorBlur: any;
  removeComponent: any;
  basicComponents: BasicComponentType[];
  wrapperComponents: WrapperComponentType[];
}

const PageStructureListElement: FC<PSLEProps> = ({
  item,
  index,
  moveElement,
  content,
  handleTextEditorBlur,
  handleSaveComponentStyles,
  removeComponent,
  basicComponents,
  wrapperComponents,
}: PSLEProps) => {
  const { t } = useTranslation();

  const editorValue = content ? content[item.id] : "";
  const isTextEditor = useMemo(
    () => item && item.id && item.id.indexOf(PAGE_CONTENT_KEY) === 0,
    [item]
  );

  const [showButtons, setShowButtons] = useState(false);
  const [showPreview, setShowPreview] = useState("");
  const toggleShowPreview = (id: string) => {
    setShowPreview((prev) => (prev === id ? "" : id));
  };

  let showStylesOptions: any = t("show_styles_options", {
    returnObjects: true,
  });
  const needToValidate = useCallback(() => {
    setShowButtons(true);
  }, []);
  const [showStylesForDevice, setShowStylesForDevice] = useState(0);
  const selectShowForDevice = (opt: any) => setShowStylesForDevice(opt.id);
  const [jskValues, jsonObjects, setJsxValues, jskErrors, jskHandleChange] =
    useFormJSK(
      {
        styleOuter: "",
        styleOuterHover: "",
        styleInner: "",
        styleInnerHover: "",
        styleOuterM: "",
        styleInnerM: "",
      },
      {
        styleOuterJson: "",
        styleOuterHoverJson: "",
        styleInnerJson: "",
        styleInnerHoverJson: "",
        styleOuterMJson: "",
        styleInnerMJson: "",
      },
      {
        styleOuterError: "",
        styleOuterHoverError: "",
        styleInnerError: "",
        styleInnerHoverError: "",
        styleOuterMError: "",
        styleInnerMError: "",
      },
      needToValidate
    );

  useEffect(() => {
    if (item) {
      setShowStylesForDevice(0);
      setJsxValues(
        {
          styleOuter: parseJsonStringifiedToTextareaJsx(item.styleOuter),
          styleOuterHover: parseJsonStringifiedToTextareaJsx(
            item.styleOuterHover
          ),
          styleOuterM: parseJsonStringifiedToTextareaJsx(item.styleOuterM),
          styleInner: parseJsonStringifiedToTextareaJsx(item.styleInner),
          styleInnerHover: parseJsonStringifiedToTextareaJsx(
            item.styleInnerHover
          ),
          styleInnerM: parseJsonStringifiedToTextareaJsx(item.styleInnerM),
        },
        {
          styleOuter: parseJsonStringifiedToJsonObject(item.styleOuter),
          styleOuterHover: parseJsonStringifiedToJsonObject(
            item.styleOuterHover
          ),
          styleOuterM: parseJsonStringifiedToJsonObject(item.styleOuterM),
          styleInner: parseJsonStringifiedToJsonObject(item.styleInner),
          styleInnerHover: parseJsonStringifiedToJsonObject(
            item.styleInnerHover
          ),
          styleInnerM: parseJsonStringifiedToJsonObject(item.styleInnerM),
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const saveComponentStyles = () => {
    handleSaveComponentStyles({
      ...item,
      styleOuter: JSON.stringify(jsonObjects.styleOuter),
      styleOuterM: JSON.stringify(jsonObjects.styleOuterM),
      styleOuterHover: JSON.stringify(jsonObjects.styleOuterHover),
      styleInner: JSON.stringify(jsonObjects.styleInner),
      styleInnerM: JSON.stringify(jsonObjects.styleInnerM),
      styleInnerHover: JSON.stringify(jsonObjects.styleInnerHover),
    });
    setShowStylesForDevice(0);
  };
  const cancelComponentStyles = () => {
    setShowStylesForDevice(0);
  };

  const editorBlur = useCallback(
    (value: string) => {
      if (item && item.id) handleTextEditorBlur(value, item.id);
    },
    [handleTextEditorBlur, item]
  );
  const clickDelete = useCallback(
    (ev: React.BaseSyntheticEvent) => {
      if (item && item.id) removeComponent(ev, item.id);
    },
    [removeComponent, item]
  );

  const foundBasicComponent = useMemo(
    () =>
      item && item.id
        ? basicComponents.find(
            (elem: BasicComponentType) =>
              elem.id && item.id.indexOf(elem.id) === 0
          )
        : undefined,
    [basicComponents, item]
  );
  const foundHoc = useMemo(
    () =>
      item && item.id
        ? wrapperComponents.find(
            (elem: WrapperComponentType) =>
              elem.id && item.id.indexOf(elem.id) === 0
          )
        : undefined,
    [wrapperComponents, item]
  );

  let componentType: string = "-";
  if (foundBasicComponent && foundBasicComponent.type)
    componentType = foundBasicComponent.type;
  if (foundHoc) componentType = t("hoc");

  let componentName = "-";
  if (foundBasicComponent && foundBasicComponent.name)
    componentName = foundBasicComponent.name;
  if (foundHoc) componentName = foundHoc.name;

  const componentNaming = useMemo(
    () => (
      <Aux>
        {isTextEditor ? "" : componentName}
        &nbsp;
        {isTextEditor ? (
          t("rich_text_editor")
        ) : (
          <span style={{ color: "var(--text-link-hover)" }}>
            ({t(componentType).toLowerCase()})
          </span>
        )}
      </Aux>
    ),
    [isTextEditor, componentName, componentType, t]
  );
  const dragTitle = useMemo(
    () => (
      <Aux>
        <span style={{ color: "var(--text-link-hover)" }}>
          {t("re_order_component")}
        </span>
        {componentNaming}
        <span style={{ color: "var(--text-link-hover)" }}>{t("here")}</span>
      </Aux>
    ),
    [componentNaming, t]
  );

  if (!item.id) return <p>-</p>;

  return (
    <DraggableListElementWrapper
      showPreview={showPreview}
      moveElement={moveElement}
      id={item.id}
      index={index}
      key={item.id} // very important
      dragTitle={dragTitle}
    >
      <div className={classes.PageComponentListElementMaskOuter}>
        <div
          className={classes.PageComponentListElementMask}
          onClick={() => toggleShowPreview(item.id)}
        >
          <p>
            <span style={{ color: "var(--text-link-hover)" }}>
              {index + 1}.&nbsp;
            </span>
            {componentNaming}
          </p>
          <div
            className={classes.IconRemoveElement}
            onClick={clickDelete}
          ></div>
        </div>
        {showPreview && showPreview === item.id ? (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className={classes.PageContentComponentDetails}>
              <p className="PaddingBSmall">{t("edit_component_styles")}</p>
              <div className={classes.SettingsGroupRow}>
                <Select
                  selectOptions={{
                    initialValue: showStylesForDevice,
                    handleChange: selectShowForDevice,
                    options: showStylesOptions,
                  }}
                />
                {showButtons && showStylesForDevice !== 0 ? (
                  <div className={classes.SettingsGroupRow}>
                    {!jskErrors.styleOuterError &&
                    !jskErrors.styleOuterHoverError &&
                    !jskErrors.styleInnerError &&
                    !jskErrors.styleInnerHoverError ? (
                      <Button
                        handleClick={saveComponentStyles}
                        className={ButtonClassTypes.GREEN}
                        fullWidth={true}
                      >
                        {t("save")}
                      </Button>
                    ) : null}
                    <Button
                      handleClick={cancelComponentStyles}
                      className={ButtonClassTypes.YELLOW}
                      fullWidth={true}
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>

            {showStylesForDevice && showStylesForDevice === 1 ? (
              <div>
                <div className={classes.PageContentComponentDetails}>
                  <div className={classes.SettingsGroupRow}>
                    <div className={classes.SettingsGroupColumn}>
                      <Input
                        label={t("outer_container_style")}
                        name={"styleOuter"}
                        value={jskValues.styleOuter}
                        error={jskErrors.styleOuterError}
                        textarea={true}
                        handleChange={jskHandleChange}
                        inputTheme={InputThemeOptions.DARK}
                        rows={jskValues.styleOuter === "" ? 2 : 5}
                      />
                    </div>
                    <div className={classes.SettingsGroupColumn}>
                      <Input
                        label={t("outer_container_hover_style")}
                        name={"styleOuterHover"}
                        value={jskValues.styleOuterHover}
                        error={jskErrors.styleOuterHoverError}
                        textarea={true}
                        handleChange={jskHandleChange}
                        inputTheme={InputThemeOptions.DARK}
                        rows={jskValues.styleOuterHover === "" ? 2 : 5}
                      />
                    </div>
                  </div>
                  <div className={classes.SettingsGroupRow}>
                    <div className={classes.SettingsGroupColumn}>
                      <Input
                        label={t("inner_container_style")}
                        name={"styleInner"}
                        value={jskValues.styleInner}
                        error={jskErrors.styleInnerError}
                        textarea={true}
                        handleChange={jskHandleChange}
                        inputTheme={InputThemeOptions.DARK}
                        rows={jskValues.styleInner === "" ? 2 : 5}
                      />
                    </div>
                    <div className={classes.SettingsGroupColumn}>
                      <Input
                        label={t("inner_container_hover_style")}
                        name={"styleInnerHover"}
                        value={jskValues.styleInnerHover}
                        error={jskErrors.styleInnerHoverError}
                        textarea={true}
                        handleChange={jskHandleChange}
                        inputTheme={InputThemeOptions.DARK}
                        rows={jskValues.styleInnerHover === "" ? 2 : 5}
                      />
                    </div>
                  </div>
                  <p className="Explanation PaddingTSmall">
                    {t("cards_expl_3")}
                  </p>
                </div>
              </div>
            ) : null}

            {showStylesForDevice && showStylesForDevice === 2 ? (
              <div>
                <div className={classes.PageContentComponentDetails}>
                  <div className={classes.SettingsGroupRow}>
                    <div className={classes.SettingsGroupColumn}>
                      <Input
                        label={t("outer_container_style")}
                        name={"styleOuterM"}
                        value={jskValues.styleOuterM}
                        error={jskErrors.styleOuterMError}
                        textarea={true}
                        handleChange={jskHandleChange}
                        inputTheme={InputThemeOptions.DARK}
                        rows={jskValues.styleOuterM === "" ? 2 : 5}
                      />
                    </div>
                    <div className={classes.SettingsGroupColumn}>
                      <Input
                        label={t("inner_container_style")}
                        name={"styleInnerM"}
                        value={jskValues.styleInnerM}
                        error={jskErrors.styleInnerMError}
                        textarea={true}
                        handleChange={jskHandleChange}
                        inputTheme={InputThemeOptions.DARK}
                        rows={jskValues.styleInnerM === "" ? 2 : 5}
                      />
                    </div>
                  </div>
                  <p className="Explanation PaddingTSmall">
                    {t("cards_expl_3")}
                  </p>
                </div>
              </div>
            ) : null}

            {isTextEditor && (
              <TextEditor handleBlur={editorBlur} value={editorValue} />
            )}
            {foundBasicComponent && (
              <div className={classes.PageContentComponentDetailsWithBottom}>
                <p className="PaddingBSmall">{t("preview")}:</p>
                {foundBasicComponent.type === BasicComponentTypes.CARD && (
                  <CardsComponent card={foundBasicComponent} />
                )}
                {foundBasicComponent.type === BasicComponentTypes.DROPDOWN && (
                  <DropdownComponent dropdown={foundBasicComponent} />
                )}
                {foundBasicComponent.type === BasicComponentTypes.FAQ && (
                  <FaqComponent faq={foundBasicComponent} />
                )}
                {foundBasicComponent.type === BasicComponentTypes.SLIDESHOW && (
                  <SlideShowComponent slideShow={foundBasicComponent} />
                )}
              </div>
            )}
            {foundHoc && (
              <div className={classes.PageContentComponentDetailsWithBottom}>
                <p className="PaddingBSmall">{t("preview")}:</p>

                <WrapperComponent item={foundHoc} />
              </div>
            )}
          </motion.div>
        ) : null}
      </div>
    </DraggableListElementWrapper>
  );
};

export default PageStructureListElement;
