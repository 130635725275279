import classes from "./basic-component.module.css";
import { motion } from "framer-motion";
import { parseStyleCard } from "../../helper-functions/general";
import { useCallback, useMemo, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { BasicComponentDataType } from "../../firebase/types";

interface FaqListElementProps {
  item: BasicComponentDataType;
  faqHoverStyle: any;
  faqStyle: any;
  styleTitle: any;
  styleText: any;
}

function FaqListElement({
  item,
  faqHoverStyle,
  faqStyle,
  styleTitle,
  styleText,
}: FaqListElementProps) {
  const { language } = useAppContext();
  const [isHovering, setIsHovering] = useState(false);
  const [show, setShow] = useState(false);

  const shouldHover = useMemo(
    () => Object.keys(faqHoverStyle).length > 0,
    [faqHoverStyle]
  );
  const toggleIsHovering = useCallback(
    (val: boolean) => shouldHover && setIsHovering(val),
    [shouldHover]
  );

  const handleClick = (ev: React.BaseSyntheticEvent) => {
    ev.stopPropagation();
    setShow((prev) => !prev);
  };

  return (
    <motion.div
      initial={{ opacity: 0.25 }}
      whileInView={{ opacity: 1 }}
      className={classes.Faq}
      style={parseStyleCard(item, isHovering ? faqHoverStyle : faqStyle)}
      onMouseEnter={() => toggleIsHovering(true)}
      onMouseLeave={() => toggleIsHovering(false)}
    >
      {item.title &&
      item.title.hasOwnProperty(language) &&
      item.title !== "" ? (
        <div onClick={handleClick} style={styleTitle}>
          {item.title[language]}
        </div>
      ) : null}

      {show &&
      item.text &&
      item.text.hasOwnProperty(language) &&
      item.text !== "" ? (
        <motion.div
          initial={{ opacity: 0.25 }}
          animate={{ opacity: 1 }}
          style={styleText}
          dangerouslySetInnerHTML={{
            __html: item.text[language],
          }}
        ></motion.div>
      ) : null}
    </motion.div>
  );
}

export default FaqListElement;
