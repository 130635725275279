import { useAppContext } from "../../../context/AppContext";
import SideMenuWraper from "../side-menu-wraper/SideMenuWraper";
import WrapperComponent from "../../wrappers-hoc/WrapperComponent";
import { useMemo } from "react";

function PublicMobileSideMenu() {
  const { wrapperComponents } = useAppContext();

  const found = useMemo(
    () =>
      wrapperComponents && wrapperComponents.find((item) => item.isSideMenu),
    [wrapperComponents]
  );

  return (
    <SideMenuWraper isPublic={true}>
      {found && <WrapperComponent item={found} />}
    </SideMenuWraper>
  );
}

export default PublicMobileSideMenu;
