import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertTextareaJsxToObject } from "../../helper-functions/general";

function useFormJSK(
  initialValues: any,
  initialObjects: any,
  initialErrors: any,
  changeOccured: () => void
) {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues);
  const [jsonObjects, setJsonObjects] = useState(initialObjects);
  const [errors, setErrors] = useState(initialErrors);

  const setJsxValues = (values: any, objects: any) => {
    setValues(values);
    setJsonObjects(objects);
  };

  const parseJsxSaveIfCorrect = (e: React.BaseSyntheticEvent) => {
    const input_value: string = e.target.value;
    const parsed = convertTextareaJsxToObject(input_value, t);
    let errValue = "";
    if (parsed.err) {
      errValue = parsed.err?.message ? parsed.err.message : t("invalid_json");
    } else {
      setJsonObjects({ ...jsonObjects, [e.target.name]: parsed });
    }
    setErrors({ ...errors, [[e.target.name] + "Error"]: errValue });
  };

  return [
    values,
    jsonObjects,
    setJsxValues,
    errors,
    (e: React.BaseSyntheticEvent) => {
      setValues({ ...values, [e.target.name]: e.target.value });
      parseJsxSaveIfCorrect(e);
      changeOccured();
    },
  ];
}

export default useFormJSK;
