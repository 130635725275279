import classes from "./layout-mockup.module.css";
import {
  AdminPaths,
  LayoutSectionTypes,
  LayoutStructureType,
} from "../../firebase/types";
import SelectLayoutSection, {
  LayoutSectionAuxOptions,
} from "../admin-pages/SelectLayoutSection";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import Aux from "../../hoc/auxiliary";
import {
  getDefaultLayoutSectionID,
  parseJsonStringifiedToJsonObject,
} from "../../helper-functions/general";

export interface LayoutMockupSelectType {
  opt: { label: string; value: string };
  type: LayoutSectionTypes;
}
const Edit_Defaults = [false, false, false, false];

function LayoutMockup({
  handleChange,
  forRoutes,
  layoutStructure,
}: {
  handleChange?: any;
  forRoutes?: boolean;
  layoutStructure?: LayoutStructureType;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    update_layout_section,
    set_layout_sections_is_default_to_false_by_type,
    layoutSections,
    settings,
    isMobileDevice,
  } = useAppContext();
  const [showSelect, setShowSelect] = useState(Edit_Defaults);

  const [headerID, setHeaderID] = useState("");
  const [contentLeftID, setContentLeftID] = useState("");
  const [contentRightID, setContentRightID] = useState("");
  const [footerID, setFooterID] = useState("");

  const toggleShowSelect = (index: number) => {
    setShowSelect((prevState) =>
      prevState.map((el, idx) => (idx === index ? !el : el))
    );
  };
  const styleOuter = useMemo(() => {
    return settings && settings.layoutStyles
      ? parseJsonStringifiedToJsonObject(
          isMobileDevice
            ? settings.layoutStyles.styleOuterM
            : settings.layoutStyles.styleOuter
        )
      : {};
  }, [settings, isMobileDevice]);

  const defaultHID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter((el) => el.type === LayoutSectionTypes.HEADER)
      ),
    [layoutSections]
  );
  useEffect(() => {
    const pageHID =
      layoutStructure && layoutStructure.header
        ? layoutStructure.header
        : defaultHID;
    setHeaderID(pageHID);
  }, [layoutStructure, defaultHID]);

  // content_left
  const defaultCLID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter(
          (el) => el.type === LayoutSectionTypes.CONTENT_LEFT
        )
      ),
    [layoutSections]
  );
  useEffect(() => {
    const pageCLID =
      layoutStructure && layoutStructure.content_left
        ? layoutStructure.content_left
        : undefined;
    setContentLeftID(pageCLID ? pageCLID : defaultCLID);
  }, [layoutStructure, defaultCLID]);

  // content_right
  const defaultCRID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter(
          (el) => el.type === LayoutSectionTypes.CONTENT_RIGHT
        )
      ),
    [layoutSections]
  );

  useEffect(() => {
    const pageCRID =
      layoutStructure && layoutStructure.content_right
        ? layoutStructure.content_right
        : undefined;
    setContentRightID(pageCRID ? pageCRID : defaultCRID);
  }, [layoutStructure, defaultCRID]);

  // footer
  const defaultFID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter((el) => el.type === LayoutSectionTypes.FOOTER)
      ),
    [layoutSections]
  );
  useEffect(() => {
    const pageFID =
      layoutStructure && layoutStructure.footer
        ? layoutStructure.footer
        : undefined;
    setFooterID(pageFID ? pageFID : defaultFID);
  }, [layoutStructure, defaultFID]);

  const handleChangeSelect = useCallback(
    (opt: { label: string; value: string }, type: LayoutSectionTypes) => {
      const handle_change = async (opt: any, type: LayoutSectionTypes) => {
        if (opt.value === LayoutSectionAuxOptions.GO_TO_EDIT) {
          navigate(`${AdminPaths.LAYOUT}/${type}`);
          return;
        }
        if (opt.value === LayoutSectionAuxOptions.DONT_USE) {
          await set_layout_sections_is_default_to_false_by_type(type);
          return;
        }

        if (!layoutSections) return;
        const lS = layoutSections.find((el) => el.id === opt.value);
        if (lS) await update_layout_section({ ...lS, isDefault: true });
      };

      if (handleChange) {
        handleChange(opt, type);
      } else handle_change(opt, type);
    },
    [
      layoutSections,
      navigate,
      update_layout_section,
      set_layout_sections_is_default_to_false_by_type,
      handleChange,
    ]
  );

  if (!layoutSections) return <div></div>;

  return (
    <div className={forRoutes ? classes.RouteOuter : classes.Outer}>
      {forRoutes ? (
        <Aux>
          <div
            className={classes.HeaderInner}
            onClick={() => toggleShowSelect(0)}
          >
            {showSelect[0] === false &&
            (headerID === defaultHID ||
              headerID === LayoutSectionAuxOptions.USE_DEFAULT) ? (
              <div className={classes.LabelRoutes}>
                <p>{t("default")} </p>
                <p className="Small">({t("click_to_change")})</p>
              </div>
            ) : (
              <div>
                <SelectLayoutSection
                  type={LayoutSectionTypes.HEADER}
                  handleChange={handleChangeSelect}
                  initialID={headerID}
                  forRoutes={true}
                />
                {headerID === defaultHID && (
                  <p className={classes.LabelCancel}>({t("cancel")})</p>
                )}
              </div>
            )}
          </div>
          <div className={classes.Middle}>
            <div className={classes.ContentInner} style={styleOuter}>
              <div
                className={classes.Column}
                onClick={() => toggleShowSelect(1)}
              >
                {showSelect[1] === false &&
                (contentLeftID === defaultCLID ||
                  contentLeftID === LayoutSectionAuxOptions.USE_DEFAULT) ? (
                  <div className={classes.LabelRoutes}>
                    <p>{t("default")} </p>
                    <p className="Small">({t("click_to_change")})</p>
                  </div>
                ) : (
                  <div>
                    <SelectLayoutSection
                      type={LayoutSectionTypes.CONTENT_LEFT}
                      handleChange={handleChangeSelect}
                      initialID={contentLeftID}
                      forRoutes={true}
                    />
                    {contentLeftID === defaultCLID && (
                      <p className={classes.LabelCancel}>({t("cancel")})</p>
                    )}
                  </div>
                )}
              </div>

              <div className={classes.Column}>
                <div className={classes.LabelRoutes}>
                  {t("route_main_details")}
                </div>
              </div>

              <div
                className={classes.Column}
                onClick={() => toggleShowSelect(2)}
              >
                {showSelect[2] === false &&
                (contentRightID === defaultCRID ||
                  contentRightID === LayoutSectionAuxOptions.USE_DEFAULT) ? (
                  <div className={classes.LabelRoutes}>
                    <p>{t("default")} </p>
                    <p className="Small">({t("click_to_change")})</p>
                  </div>
                ) : (
                  <div>
                    <SelectLayoutSection
                      type={LayoutSectionTypes.CONTENT_RIGHT}
                      handleChange={handleChangeSelect}
                      initialID={contentRightID}
                      forRoutes={true}
                    />
                    {contentRightID === defaultCRID && (
                      <p className={classes.LabelCancel}>({t("cancel")})</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={classes.HeaderInner}
            onClick={() => toggleShowSelect(3)}
          >
            {showSelect[3] === false &&
            (footerID === defaultFID ||
              footerID === LayoutSectionAuxOptions.USE_DEFAULT) ? (
              <div className={classes.LabelRoutes}>
                <p>{t("default")} </p>
                <p className="Small">({t("click_to_change")})</p>
              </div>
            ) : (
              <div>
                <SelectLayoutSection
                  type={LayoutSectionTypes.FOOTER}
                  handleChange={handleChangeSelect}
                  initialID={footerID}
                  forRoutes={true}
                />
                {footerID === defaultFID && (
                  <p className={classes.LabelCancel}>({t("cancel")})</p>
                )}
              </div>
            )}
          </div>
        </Aux>
      ) : (
        <Aux>
          <div className={classes.HeaderInner}>
            <SelectLayoutSection
              type={LayoutSectionTypes.HEADER}
              handleChange={handleChangeSelect}
              initialID={defaultHID}
            />
          </div>
          <div className={classes.Middle}>
            <div className={classes.ContentInner} style={styleOuter}>
              <div className={classes.Column}>
                <SelectLayoutSection
                  type={LayoutSectionTypes.CONTENT_LEFT}
                  handleChange={handleChangeSelect}
                  initialID={defaultCLID}
                />
              </div>

              <div className={classes.Column}>
                <div className={classes.LabelRoutes}>{t("routes")}</div>
              </div>

              <div className={classes.Column}>
                <SelectLayoutSection
                  type={LayoutSectionTypes.CONTENT_RIGHT}
                  handleChange={handleChangeSelect}
                  initialID={defaultCRID}
                />
              </div>
            </div>
          </div>
          <div className={classes.HeaderInner}>
            <SelectLayoutSection
              type={LayoutSectionTypes.FOOTER}
              handleChange={handleChangeSelect}
              initialID={defaultFID}
            />
          </div>
        </Aux>
      )}
    </div>
  );
}

export default LayoutMockup;
