import classes from "./select.module.css";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import useOnClickOutside from "../../../hoc/hooks/useOnClickOutside";

interface SelectOptionsType {
  disabled?: boolean;
  errorText?: string;
  initialValue?: string | number;
  keepInitialValue?: boolean;
  mouseOverTitle?: string;
  optionsOpenAbove?: boolean;
  optionTextAlign?: SelectOptionTextAlign;
  overallWidth?: string;
  menuLabel?: string;

  handleChange: (option: SelectOptionType) => any;
  options: SelectOptionType[];
  searchable?: boolean;
}

export interface SelectOptionType {
  value: string | number;
  label: string;
  description?: string;
  id?: string | number;
  isCategoryName?: boolean;
}

export const enum SelectOptionTextAlign {
  LEFT,
  CENTER,
  RIGHT,
}

function Select({ selectOptions }: { selectOptions: SelectOptionsType }) {
  const {
    disabled,
    errorText,
    mouseOverTitle,
    options,
    optionsOpenAbove,
    optionTextAlign,
    overallWidth,
    menuLabel,
    keepInitialValue,
    searchable,
  } = selectOptions;
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const searchRef = useRef<any>();

  useOnClickOutside([searchRef], () =>
    setTimeout(() => {
      setShowOptions(false);
      setSearch("");
    }, 150)
  );

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    SelectOptionType | undefined
  >();
  const [optsHeight, setOptsHeight] = useState("");

  const mainOuterRef = useRef<any>();
  const optionsOuter = useRef<any>();

  useEffect(() => {
    if (
      selectOptions?.options &&
      selectOptions?.hasOwnProperty("initialValue")
    ) {
      const found = selectOptions.options.find(
        (opt: SelectOptionType) => opt.value === selectOptions.initialValue
      );
      if (found) setSelectedOption(found);
    }
  }, [selectOptions]);

  useEffect(() => {
    const element = mainOuterRef.current;
    element.tabIndex = 0;
    const callback = () => setShowOptions(false);
    if (element) element.addEventListener("blur", callback);

    return () => {
      if (element) {
        element.removeEventListener("blur", callback, false);
      }
    };
  }, []);

  useEffect(() => {
    const optionsElement = optionsOuter?.current;
    if (optionsOuter && optionsElement && optionsOpenAbove) {
      const nmbInPixels = -1 * (optionsElement.offsetHeight + 1.5);
      setOptsHeight(nmbInPixels + "px");
    }
  }, [showOptions, optionsOpenAbove]);

  const toggleOptions = (ev: SyntheticEvent) => {
    ev.stopPropagation();
    setShowOptions((prev) => !prev);
    // searchRef.current.focus();
  };

  const handleOptionClick = (ev: SyntheticEvent, option: any) => {
    if (!menuLabel || !keepInitialValue) setSelectedOption(option);
    if (keepInitialValue && selectOptions?.hasOwnProperty("initialValue")) {
      const found = selectOptions.options.find(
        (opt: SelectOptionType) => opt.value === selectOptions.initialValue
      );
      if (found) setSelectedOption(found);
    }
    setShowOptions(false);
    selectOptions.handleChange(option);
  };

  const getOptionsClass = () => {
    let result = [classes.Options];

    if (showOptions) {
      result.push(classes.Visible);
    }
    if (optionsOpenAbove) {
      result.push(classes.OpenAbove);
    }
    return result.join(" ");
  };
  const getOptionClass = (opt: any, isHeader?: boolean) => {
    let result = [classes.Option];
    if (isHeader) result.push(classes.Header);
    if (selectedOption && opt.value === selectedOption.value) {
      result.push(classes.OptionSelected);
    }
    switch (optionTextAlign) {
      case SelectOptionTextAlign.LEFT:
        result.push(classes.AlignLeft);
        break;
      case SelectOptionTextAlign.CENTER:
        result.push(classes.AlignCenter);
        break;
      case SelectOptionTextAlign.RIGHT:
        result.push(classes.AlignRight);
        break;

      default:
        break;
    }
    return result.join(" ");
  };
  const updateSearch = (ev: React.BaseSyntheticEvent) => {
    setSearch(ev.target.value);
  };

  const sortedOptions = useMemo(
    () =>
      options.filter(
        (opt) =>
          opt.isCategoryName ||
          !search ||
          opt.label.toLowerCase().includes(search.toLowerCase())
      ),
    [options, search]
  );

  return (
    <div
      className={menuLabel ? classes.MenuOuter : classes.Outer}
      style={{ width: overallWidth ? overallWidth : "auto" }}
      ref={mainOuterRef}
    >
      <div
        title={mouseOverTitle}
        className={[
          classes.SelectedValueOuter,
          errorText ? classes.SelectedValueOuterError : null,
          disabled ? classes.SelectedValueOuterDisabled : null,
        ].join(" ")}
        onClick={toggleOptions}
      >
        {search ? (
          <p className={classes.SelectedValue}>&nbsp;</p>
        ) : (
          <p className={classes.SelectedValue}>
            {selectedOption ? (
              selectedOption.label
            ) : menuLabel ? (
              menuLabel
            ) : (
              <span className="Explanation">{t("select")}...</span>
            )}
          </p>
        )}

        {searchable && (
          <div className={classes.SearchOuter}>
            <input ref={searchRef} onChange={updateSearch} value={search} />
          </div>
        )}

        <div className={classes.MarkerArrowOuter}>
          <div className={classes.MarkerArrow}></div>
        </div>
      </div>

      {!disabled && (
        <div
          className={getOptionsClass()}
          style={optionsOpenAbove ? { top: optsHeight } : {}}
          ref={optionsOuter}
        >
          {sortedOptions && sortedOptions.length ? (
            sortedOptions.map((opt: any, idx: number) => (
              <div
                key={idx}
                className={getOptionClass(opt, opt.isCategoryName)}
                onClick={(ev) => {
                  if (!opt.isCategoryName) handleOptionClick(ev, opt);
                }}
              >
                <p>{opt.label}</p>
                {opt.description && (
                  <p className={classes.Description}>{opt.description}</p>
                )}
              </div>
            ))
          ) : (
            <div className={classes.NoOption}>
              <p>{t("no_option")}</p>
            </div>
          )}
        </div>
      )}

      {errorText && <p className={classes.ErrorMessage}>{errorText}</p>}
    </div>
  );
}

export default Select;
