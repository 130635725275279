export const enum MediaIconsType {
  FACEBOOK,
  INSTAGRAM,
  TWITTER,
  WHATSAPP,
}

export interface SettingsType {
  bName?: string | undefined;
  bLegalName?: string | undefined;
  bEmail?: string | undefined;
  bPhone?: string | undefined;
  bLegalData?: string | undefined;
  bLegalDataAux?: string | undefined;
  bAddress?: string | undefined;
  defLanguage?: string | undefined;
  updatedAt?: number;
  layoutStyles?: LayoutStyleType;
}
export interface LayoutStyleType {
  styleHeader?: string;
  styleHeaderM?: string;
  styleFooter?: string;
  styleFooterM?: string;
  styleMiddle?: string;
  styleMiddleM?: string;
  styleColumn1?: string;
  styleColumn2?: string;
  styleColumn3?: string;
  styleColumn1M?: string;
  styleColumn2M?: string;
  styleColumn3M?: string;
}
export interface PageType {
  order?: number;
  id?: string;
  createdAt?: number;
  path: string;
  status?: boolean;
  updatedAt?: number;
  data: PageDataType[];
  sectionLabel?: string;
  structure?: PageStructure[];
  layoutStructure?: LayoutStructureType;
}

export interface LayoutStructureType {
  header?: string;
  content_left?: string;
  content_right?: string;
  footer?: string;
  styleOuter?: string;
  styleOuterM?: string;
}
export interface PageStructure {
  id: string;
  order: number;
  styleOuter?: string;
  styleOuterHover?: string;
  styleInner?: string;
  styleInnerHover?: string;
  styleOuterM?: string;
  styleInnerM?: string;
}

export interface PageDataType {
  content?: any; // content_1658594575483: string
  language: string;
  linkLabel: string;
}

export interface DbMediaType {
  id?: string;
  name?: string;
  url?: string;
  bucketPath?: string;
  size?: number;
  type?: string;
  createdAt?: number;
}

export const DataPerLanguageDefaults = {
  de: "",
  en: "",
  ro: "",
  es: "",
};
export interface DataPerLanguage {
  de?: string;
  en?: string;
  ro?: string;
  es?: string;
}

export interface BasicComponentType {
  type?: string;
  templateUsed?: string;
  createdAt?: number;
  updatedAt?: number;
  id?: string;
  name: string;
  description?: string;
  data?: BasicComponentDataType[];
  styleOuter?: string;
  styleList?: string;
  styleListElement?: string;
  styleListElementHover?: string;
  styleTitle?: string;
  styleText?: string;
  styleOuterM?: string;
  styleListM?: string;
  styleListElementM?: string;
  styleTitleM?: string;
  styleTextM?: string;

  // specific to certain components:
  styleOutlet?: string;
  styleControls?: string;
  styleControl?: string;
  styleOutletM?: string;
  styleControlsM?: string;
  styleControlM?: string;
  // dropdown:
  label?: any;
  styleLabel?: string;
  styleLabelHover?: string;
  styleLabelM?: string;
  styleListHover?: string;
  isLanguageSelect?: boolean;
}

export interface BasicComponentDataType {
  id?: number;
  title?: any;
  text?: any;
  backgroundImage?: string;
  backgroundColor?: string;
  itemClick?: string;
  openIn?: number;
  // dropdown:
  languageCode?: string;
}

export const enum BasicComponentTypes {
  CARD = "card",
  FAQ = "faq",
  SLIDESHOW = "slide_show",
  DROPDOWN = "dropdown",
}

export interface WrapperComponentType {
  templateUsed?: string;
  createdAt?: number;
  updatedAt?: number;
  id?: string;
  name: string;
  description?: string;
  styleOuter?: string;
  styleOuterHover?: string;
  styleOuterM?: string;
  styleInner?: string;
  styleInnerHover?: string;
  styleInnerM?: string;
  childrenIDs?: string[];
  isSideMenu?: boolean;
}

export const NewPageParameter = "create-a-new-page";
export const NewTemplateDropdownParameter = "create-a-dropdown-template";
export const NewTemplateCardParameter = "create-a-card-template";
export const NewTemplateFaqParameter = "create-a-faq-template";
export const NewTemplateSlideshowParameter = "create-a-slide_show-template";
export const NewTemplateWrapperParameter = "create-a-wrapper-template";
export const NewSectionParameter = "create-a-section";
export const HomePageIdentifier = "home-page";
export const CookiePolicyPageIdentifier = "cookies-policy";
export const ActionActionOpenSideMenu = "action-open-mobile-side-menu";

export enum AdminPaths {
  BASE = "/admin",
  USER = "/admin/user",
  THEMES = "/admin/themes",
  LAYOUT = "/admin/layout",
  ROUTES = "/admin/routes",
  BASIC_COMPONENTS = "/admin/basic-components",
  HOCS = "/admin/high-order-components",
  MEDIA = "/admin/media",
  SETTINGS = "/admin/settings",
}

export interface TranslationMonthsArray {
  value: number;
  label: string;
}

export interface LayoutSectionType {
  type?: string;
  createdAt?: number;
  updatedAt?: number;
  id?: string;
  isDefault?: boolean;
  name?: string;
  description?: string;
  structure?: any[];
}
export interface LayoutSectionStructure {
  id: string;
  order: number;
  createdAt: number;
  updatedAt: number;
}

export const enum LayoutSectionTypes {
  HEADER = "header",
  MIDDLE = "middle",
  CONTENT_LEFT = "content_left",
  ROUTES = "routes",
  CONTENT_RIGHT = "content_right",
  FOOTER = "footer",
}
export const enum LayoutStylesTypes {
  HEADER = "header",
  MIDDLE = "middle",
  COLUMN1 = "column1",
  COLUMN2 = "column2",
  COLUMN3 = "column3",
  FOOTER = "footer",
}

export const enum LayoutComponentShowTypes {
  USE_ALL_OPTIONS = "use_all_including_page_settings",
  USE_DEFAULT_ELEMENT = "use_default_per_layout_section",
  USE_EDIT_ELEMENT = "use_data_provided_in_props",
}
