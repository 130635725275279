import classes from "./side-menu.module.css";
import { Link, PathMatch, useMatch, useResolvedPath } from "react-router-dom";
import type { LinkProps } from "react-router-dom";

function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match: PathMatch<string> | null | Boolean;
  match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={to} {...props}>
      <div className={match ? classes.Active : ""}>{children}</div>
    </Link>
  );
}

export default CustomLink;
