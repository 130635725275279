import classes from "./side-menu.module.css";
import {
  Link,
  PathMatch,
  useLocation,
  useMatch,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import { detectSpecialPagesActive } from "../../../../helper-functions/general";

function CustomSubMenuLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match: PathMatch<string> | null | Boolean;
  match = useMatch({ path: resolved.pathname, end: false });
  const { p } = useParams();
  const { pathname } = useLocation();

  if (!match && p) {
    match = pathname === to ? true : false;
  }
  if (!match) {
    match = detectSpecialPagesActive(pathname, to);
  }

  return (
    <Link to={to} {...props}>
      <div className={match ? classes.Active : ""}>{children}</div>
    </Link>
  );
}

export default CustomSubMenuLink;
