import classes from "./layout-and-header.module.css";
import { useAppContext } from "../../../context/AppContext";
import MenuToggle from "../side-menu-wraper/MenuToggle";
import LogoInHeader from "../../logo-in-header/LogoInHeader";

function Header() {
  const { currentUser, isMobileDevice } = useAppContext();

  let userDetail = "";
  if (currentUser) {
    userDetail = currentUser.displayName
      ? currentUser.displayName
      : currentUser.email;
  }

  return (
    <div className={classes.HeaderAdminOuter}>
      <div className={classes.HeaderAdmin}>
        <MenuToggle />
        <LogoInHeader />

        {!isMobileDevice && (
          <p className={classes.SideMenuUserName}>{userDetail}</p>
        )}
      </div>
    </div>
  );
}

export default Header;
