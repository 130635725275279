import { lazy, Suspense } from "react";
import { BasicComponentType } from "../../firebase/types";
const SlideShowLazyLoaded = lazy(() => import("./SlideShowLazyLoaded"));

const SlideShowComponent = ({
  slideShow,
}: {
  slideShow: BasicComponentType;
}) => {
  return (
    <Suspense fallback={<p></p>}>
      <SlideShowLazyLoaded slideShow={slideShow} />
    </Suspense>
  );
};

export default SlideShowComponent;
