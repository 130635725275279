import { useAppContext } from "../../context/AppContext";
import {
  BasicComponentDataType,
  BasicComponentType,
} from "../../firebase/types";
import { parseJsonStringifiedToJsonObject } from "../../helper-functions/general";

function BasicComponentDataTitle(
  className: any,
  data: BasicComponentDataType,
  bc: BasicComponentType
) {
  const { language, isMobileDevice } = useAppContext();

  return data.title &&
    data.title.hasOwnProperty(language) &&
    data.title[language] !== "" ? (
    <div
      className={className}
      style={parseJsonStringifiedToJsonObject(
        isMobileDevice ? bc.styleTitleM : bc.styleTitle
      )}
    >
      {data.title[language]}
    </div>
  ) : null;
}

export default BasicComponentDataTitle;
