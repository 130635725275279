import { useTranslation } from "react-i18next";
import classes from "./side-menu.module.css";
import CustomLink from "./CustomLink";
import CustomSubMenuLink from "./CustomSubMenuLink";

import { useAppContext } from "../../../../context/AppContext";
import React, { useEffect, useMemo, useState } from "react";

import UserColor from "../../../../assets/svg/user_color.svg";
import MediaSvg from "../../../../assets/svg/media_color.svg";
import SettingsSvg from "../../../../assets/svg/settings_color.svg";
import ThemesSvg from "../../../../assets/svg/themes_color.svg";
import HocSvg from "../../../../assets/svg/hoc_color.svg";
import LayoutSvg from "../../../../assets/svg/layout_color.svg";
import PagesSvg from "../../../../assets/svg/pages_color.svg";
import ComponentsSvg from "../../../../assets/svg/components_color.svg";

import LinkLabel from "../../../link-label-by-language/LinkLabel";

import SideMenuWraper from "../../side-menu-wraper/SideMenuWraper";
import { AdminPaths, LayoutSectionTypes } from "../../../../firebase/types";
import { useLocation } from "react-router-dom";

const enum HasSubMenuKey {
  NONE = 0,
  LAYOUT = 1,
  ROUTES = 2,
  BASIC_COMPONENTS = 3,
  HOCS = 4,
}

function SideMenu() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { pages, toggle_side_drawer } = useAppContext();

  const [showSubmenu, setShowSubmenu] = useState(HasSubMenuKey.NONE);
  useEffect(() => {
    if (pages) {
      let changeTo = HasSubMenuKey.NONE;
      if (pathname.includes(AdminPaths.ROUTES)) {
        changeTo = HasSubMenuKey.ROUTES;
      }
      if (pathname.includes(AdminPaths.BASIC_COMPONENTS)) {
        changeTo = HasSubMenuKey.BASIC_COMPONENTS;
      }
      if (pathname.includes(AdminPaths.HOCS)) {
        changeTo = HasSubMenuKey.HOCS;
      }
      if (pathname.includes(AdminPaths.LAYOUT)) {
        changeTo = HasSubMenuKey.LAYOUT;
      }
      setShowSubmenu(changeTo);
    }
  }, [pages, pathname]);

  const click_subMenu = (ev: React.BaseSyntheticEvent) => {
    ev.stopPropagation();
    toggle_side_drawer();
  };

  const layoutSubMenu = useMemo(
    () => [
      {
        label: t("header"),
        path: `${AdminPaths.LAYOUT}/${LayoutSectionTypes.HEADER}`,
      },
      {
        label: t("content_left"),
        path: `${AdminPaths.LAYOUT}/${LayoutSectionTypes.CONTENT_LEFT}`,
      },
      {
        label: t("routes"),
        path: AdminPaths.ROUTES,
      },
      {
        label: t("content_right"),
        path: `${AdminPaths.LAYOUT}/${LayoutSectionTypes.CONTENT_RIGHT}`,
      },
      {
        label: t("footer"),
        path: `${AdminPaths.LAYOUT}/${LayoutSectionTypes.FOOTER}`,
      },
    ],
    [t]
  );
  const componentsSubMenu = useMemo(
    () => [
      {
        label: t("slide_shows"),
        path: `${AdminPaths.BASIC_COMPONENTS}/slide-shows`,
      },
      { label: t("cards"), path: `${AdminPaths.BASIC_COMPONENTS}/cards` },
      { label: t("faq.abv"), path: `${AdminPaths.BASIC_COMPONENTS}/faqs` },
      {
        label: t("dropdowns"),
        path: `${AdminPaths.BASIC_COMPONENTS}/dropdowns`,
      },
      {
        label: t("templates"),
        path: `${AdminPaths.BASIC_COMPONENTS}/templates`,
      },
    ],
    [t]
  );
  const wrappersSubMenu = useMemo(
    () => [
      { label: t("items"), path: `${AdminPaths.HOCS}/items` },
      { label: t("templates"), path: `${AdminPaths.HOCS}/templates` },
    ],
    [t]
  );
  const menuStructure = [
    {
      label: t("user"),
      path: AdminPaths.USER,
      svg: UserColor,
    },
    // {
    //   label: t("themes"),
    //   path: AdminPaths.THEMES,
    //   svg: ThemesSvg,
    // },
    {
      label: t("layout"),
      path: AdminPaths.LAYOUT,
      svg: LayoutSvg,
      hasSubMenu: HasSubMenuKey.LAYOUT,
    },
    {
      label: t("routes"),
      path: AdminPaths.ROUTES,
      svg: PagesSvg,
      hasSubMenu: HasSubMenuKey.ROUTES,
    },
    {
      label: t("components"),
      path: AdminPaths.BASIC_COMPONENTS,
      svg: ComponentsSvg,
      hasSubMenu: HasSubMenuKey.BASIC_COMPONENTS,
    },
    {
      label: t("wrapper_components"),
      path: AdminPaths.HOCS,
      svg: HocSvg,
      hasSubMenu: HasSubMenuKey.HOCS,
    },
    {
      label: t("media"),
      path: AdminPaths.MEDIA,
      svg: MediaSvg,
    },
    {
      label: t("settings"),
      path: AdminPaths.SETTINGS,
      svg: SettingsSvg,
    },
  ];

  return (
    <SideMenuWraper>
      {menuStructure.map((menuItem, idx: number) => {
        return (
          <div
            className={classes.NavItem}
            key={idx}
            onClick={toggle_side_drawer}
          >
            <CustomLink to={menuItem.path}>
              <div className={classes.ImageOuter}>
                <img src={menuItem.svg} alt={t("user")} />
              </div>
              <p className={classes.MenuLabel}>{menuItem.label}</p>
            </CustomLink>

            {menuItem.hasSubMenu === HasSubMenuKey.LAYOUT &&
            showSubmenu === HasSubMenuKey.LAYOUT
              ? layoutSubMenu.map((subMenu: any, idx: number) => {
                  return (
                    <CustomSubMenuLink
                      to={subMenu.path}
                      key={idx}
                      onClick={(ev) => click_subMenu(ev)}
                    >
                      <div className={classes.ImageOuter}>&nbsp;</div>
                      <p>
                        {subMenu.label}
                        {/* <LinkLabel page={pageData} /> */}
                      </p>
                    </CustomSubMenuLink>
                  );
                })
              : null}

            {menuItem.hasSubMenu === HasSubMenuKey.ROUTES &&
            pages &&
            showSubmenu === HasSubMenuKey.ROUTES
              ? pages.map((pageData: any, idx: number) => {
                  return (
                    <CustomSubMenuLink
                      to={`${AdminPaths.ROUTES}/${pageData.path}`}
                      key={idx}
                      onClick={(ev) => click_subMenu(ev)}
                    >
                      <div className={classes.ImageOuterSub}>&nbsp;</div>
                      <p>
                        <LinkLabel page={pageData} />
                      </p>
                    </CustomSubMenuLink>
                  );
                })
              : null}

            {menuItem.hasSubMenu === HasSubMenuKey.BASIC_COMPONENTS &&
            showSubmenu === HasSubMenuKey.BASIC_COMPONENTS
              ? componentsSubMenu.map((subMenu: any, idx: number) => {
                  return (
                    <CustomSubMenuLink
                      to={subMenu.path}
                      key={idx}
                      onClick={(ev) => click_subMenu(ev)}
                    >
                      <div className={classes.ImageOuter}>&nbsp;</div>
                      <p>{subMenu.label}</p>
                    </CustomSubMenuLink>
                  );
                })
              : null}

            {menuItem.hasSubMenu === HasSubMenuKey.HOCS &&
            showSubmenu === HasSubMenuKey.HOCS
              ? wrappersSubMenu.map((subMenu: any, idx: number) => {
                  return (
                    <CustomSubMenuLink
                      to={subMenu.path}
                      key={idx}
                      onClick={(ev) => click_subMenu(ev)}
                    >
                      <div className={classes.ImageOuter}>&nbsp;</div>
                      <p>{subMenu.label}</p>
                    </CustomSubMenuLink>
                  );
                })
              : null}
          </div>
        );
      })}
    </SideMenuWraper>
  );
}

export default SideMenu;
