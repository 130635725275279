import classes from "./side-menu-wraper.module.css";
import { useAppContext } from "../../../context/AppContext";

function Backdrop() {
  const { showSideDrawer, toggle_side_drawer } = useAppContext();

  return (
    <div>
      {showSideDrawer && (
        <div className={classes.Backdrop} onClick={toggle_side_drawer}></div>
      )}
    </div>
  );
}

export default Backdrop;
