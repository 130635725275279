import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  // onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "./firebase-config";
import {
  BasicComponentType,
  DbMediaType,
  LayoutSectionType,
  SettingsType,
  WrapperComponentType,
} from "./types";

const SET_DOC_REF = doc(firestore, "settings/settings_document");
const PAGES_COLLECTION = collection(firestore, "pages");
const MEDIA_COLLECTION = collection(firestore, "media");

const BASIC_COMPONENTS_COLLECTION = collection(firestore, "basic-components");
const WRAPPER_COMPONENTS_COLLECTION = collection(
  firestore,
  "wrapper-components"
);
const TEMPLATE_BASIC_COMPONENTS_COLLECTION = collection(
  firestore,
  "template-basic-components"
);
const TEMPLATE_WRAPPER_COMPONENTS_COLLECTION = collection(
  firestore,
  "template-wrapper-components"
);
const LAYOUT_SECTIONS_COLLECTION = collection(firestore, "layout-sections");

export const getDocsFromCollection = async (
  collection: CollectionReference,
  nmb?: number
) => {
  try {
    const collectionQuery = query(collection, limit(nmb ? nmb : 99));
    const snapShot = await getDocs(collectionQuery);
    let data: object[] = [];
    snapShot.forEach((snap) => {
      data.push({ id: snap.id, ...snap.data() });
    });
    return data;
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};
export const createDocInCollection = async (
  collection: CollectionReference,
  request: object
) => {
  try {
    const docRef = await addDoc(collection, request);
    let docData;
    // read doc:
    const snapShot = await getDoc(docRef);
    if (snapShot.exists()) docData = { id: snapShot.id, ...snapShot.data() };
    return docData;
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};
export const deleteDocByPath = async (path: string) => {
  try {
    const docRef = doc(firestore, path);
    return await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
  }
};

export const getSettings = async () => {
  try {
    const snapShot = await getDoc(SET_DOC_REF);
    let data;
    if (snapShot.exists()) {
      data = snapShot.data();
    }
    return data;
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};
export const saveSettings = async (reqObj: SettingsType) => {
  // first create doc & update only provided data fields with merge:true
  try {
    await setDoc(
      SET_DOC_REF,
      { ...reqObj, updatedAt: Date.now() },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
    return { err: error };
  }
  // should not block the UI for this!
  // if works offline this will get stuck if waiting for the response!
  // recommended to update the interface / AppContext & carry on
};
export const addMediaFile = async (media: DbMediaType) => {
  try {
    const request = { ...media, createdAt: Date.now() };
    const dbRef = await addDoc(MEDIA_COLLECTION, request);
    const fileFromDb = await getDoc(dbRef);
    const saved = {
      id: fileFromDb.id,
      ...fileFromDb.data(),
    };
    return saved;
  } catch (error) {
    console.log(error);
  }
};
export const getMediaURLs = async () => {
  try {
    const snapShot = await getDocs(MEDIA_COLLECTION);
    let data: DbMediaType[] = [];
    snapShot.forEach((snap) => {
      data.push({ id: snap.id, ...snap.data() });
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPageByPath = async (path: string) => {
  try {
    const pageQuery = query(
      collection(firestore, "pages"),
      where("path", "==", path),
      limit(1)
    );
    const snapShot = await getDocs(pageQuery);
    let data;
    snapShot.forEach((snap) => {
      data = { id: snap.id, ...snap.data() };
    });
    return data;
  } catch (error) {
    console.log(error);
    // return error;
  }
};
export const getPages = async (nmb?: number) => {
  return getDocsFromCollection(PAGES_COLLECTION);
};
export const createPage = async (pageObj: object) => {
  try {
    const docReference = await addDoc(PAGES_COLLECTION, pageObj);
    let docData;
    // read doc:
    const page = await getDoc(docReference);
    if (page.exists()) docData = { id: page.id, ...page.data() };
    return docData;
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};
export const updatePage = async (pageObj: any) => {
  try {
    const page = doc(firestore, `pages/${pageObj.id}`);
    await updateDoc(page, pageObj);
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};

// BASIC COMPONENTS
export const getBasicComponents = async (nmb?: number) => {
  return await getDocsFromCollection(BASIC_COMPONENTS_COLLECTION);
};
export const createBasicComponent = async (request: BasicComponentType) => {
  return await createDocInCollection(BASIC_COMPONENTS_COLLECTION, request);
};
export const updateBasicComponent = async (request: any) => {
  try {
    const docRef = doc(firestore, `basic-components/${request.id}`);
    await updateDoc(docRef, request);
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};

// template-components:
export const getTemplateBasicComponents = async (nmb?: number) => {
  return getDocsFromCollection(TEMPLATE_BASIC_COMPONENTS_COLLECTION);
};
export const createTemplateBasicComponent = async (reqObj: object) => {
  try {
    const docReference = await addDoc(
      TEMPLATE_BASIC_COMPONENTS_COLLECTION,
      reqObj
    );
    let docData;
    const doc = await getDoc(docReference);
    if (doc.exists()) docData = { id: doc.id, ...doc.data() };
    return docData;
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};
export const updateTemplateBasicComponent = async (reqObj: any) => {
  try {
    const docRef = doc(firestore, `template-basic-components/${reqObj.id}`);
    await updateDoc(docRef, reqObj);
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};

// wrapper-components
export const getWrapperComponents = async (nmb?: number) => {
  return await getDocsFromCollection(WRAPPER_COMPONENTS_COLLECTION);
};
export const createWrapperComponent = async (request: WrapperComponentType) => {
  return await createDocInCollection(WRAPPER_COMPONENTS_COLLECTION, request);
};
export const updateWrapperComponent = async (request: any) => {
  try {
    const docRef = doc(firestore, `wrapper-components/${request.id}`);
    await updateDoc(docRef, request);
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};

// template-wrappers:
export const getTemplateWrapperComponents = async (nmb?: number) => {
  return getDocsFromCollection(TEMPLATE_WRAPPER_COMPONENTS_COLLECTION);
};
export const createTemplateWrapperComponent = async (reqObj: object) => {
  try {
    const docReference = await addDoc(
      TEMPLATE_WRAPPER_COMPONENTS_COLLECTION,
      reqObj
    );
    let docData;
    const doc = await getDoc(docReference);
    if (doc.exists()) docData = { id: doc.id, ...doc.data() };
    return docData;
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};
export const updateTemplateWrapperComponent = async (reqObj: any) => {
  try {
    const docRef = doc(firestore, `template-wrapper-components/${reqObj.id}`);
    await updateDoc(docRef, reqObj);
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};

// layout-sections:

// wrapper-components
export const getLayoutSections = async (nmb?: number) => {
  return await getDocsFromCollection(LAYOUT_SECTIONS_COLLECTION);
};
export const createLayoutSection = async (request: LayoutSectionType) => {
  return await createDocInCollection(LAYOUT_SECTIONS_COLLECTION, request);
};
export const updateLayoutSection = async (request: any) => {
  try {
    const docRef = doc(firestore, `layout-sections/${request.id}`);
    await updateDoc(docRef, request);
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};
export const updateLayoutSections = async (request: any[]) => {
  try {
    for (let x = 0; x < request.length; x++) {
      const docRef = doc(firestore, `layout-sections/${request[x].id}`);
      await updateDoc(docRef, request[x]);
    }
  } catch (error) {
    console.log(error);
    return { err: error };
  }
};

// needs testing / if needed:
// export const listenToSettingsDoc = () => {
//   onSnapshot(SET_DOC_REF, (docSnapShot) => {
//     if (docSnapShot.exists()) {
//       const data = docSnapShot.data();
//       console.log(`ON snap SHOT data CHANGED: ${JSON.stringify(data)}`);
//     }
//   });
// };
