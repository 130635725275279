import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

import LayoutClient from "./layout/LayoutClient";
import RequireAuth from "../components/navigation/access-public/RequireAuth";
import AdminLanding from "../routes/admin/Landing";
import LayoutAdmin from "./layout/LayoutAdmin";
import { AdminPaths, BasicComponentTypes } from "../firebase/types";

const Authenticate = React.lazy(
  () => import("../routes/public/authenticate/Authenticate")
);
const DynamicPage = React.lazy(
  () => import("../routes/public/dynamic-pages/Dynamic-pages")
);
const PageNotFound = React.lazy(
  () => import("../routes/page-not-found/PageNotFound")
);

const Themes = React.lazy(() => import("../routes/admin/Themes"));
const Layout = React.lazy(() => import("../routes/admin/layout/Layout"));
const LayoutSectionByType = React.lazy(
  () => import("../routes/admin/layout/LayoutSectionByType")
);
const EditLayoutSection = React.lazy(
  () => import("../routes/admin/layout/EditLayoutSection")
);
const Pages = React.lazy(() => import("../routes/admin/Pages"));
const PageById = React.lazy(() => import("../routes/admin/PageById"));
const Components = React.lazy(
  () => import("../routes/admin/basic-components/Components")
);
const BasicComponents = React.lazy(
  () => import("../routes/admin/basic-components/BasicComponents")
);
const HocComponents = React.lazy(
  () => import("../routes/admin/high-order-components/HocMainView")
);
const HighOrderComponents = React.lazy(
  () => import("../routes/admin/high-order-components/HighOrderComponents")
);
const HocTemplates = React.lazy(
  () => import("../routes/admin/high-order-components/HocTemplates")
);
const TemplateHocById = React.lazy(
  () => import("../routes/admin/high-order-components/TemplateHocById")
);
const Templates = React.lazy(
  () => import("../routes/admin/basic-components/Templates")
);
const TemplateComponentById = React.lazy(
  () => import("../routes/admin/basic-components/TemplateComponentById")
);
const Media = React.lazy(() => import("../routes/admin/media/Media"));
const Settings = React.lazy(() => import("../routes/admin/Settings"));

const Routing = () => {
  const { currentUser } = useAppContext();

  return (
    <Routes>
      <Route path="/" element={<LayoutClient />}>
        <Route
          index
          element={
            <Suspense fallback={<div></div>}>
              <DynamicPage homePage={true} />
            </Suspense>
          }
        />
        <Route
          path="/authenticate"
          element={
            <Suspense fallback={<div></div>}>
              <Authenticate />
            </Suspense>
          }
        />
        <Route
          path="/page/:id"
          element={
            <Suspense fallback={<div></div>}>
              <DynamicPage />
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Suspense fallback={<div></div>}>
              <PageNotFound />
            </Suspense>
          }
        />
      </Route>

      <Route
        path={AdminPaths.BASE}
        element={
          <RequireAuth user={currentUser}>
            <LayoutAdmin />
          </RequireAuth>
        }
      >
        <Route
          path={AdminPaths.USER}
          element={
            <Suspense fallback={<div></div>}>
              <AdminLanding />
            </Suspense>
          }
        />
        <Route
          path={AdminPaths.THEMES}
          element={
            <Suspense fallback={<div></div>}>
              <Themes />
            </Suspense>
          }
        />
        <Route
          path={AdminPaths.LAYOUT}
          element={
            <Suspense fallback={<div></div>}>
              <Layout />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/:type`}
          element={
            <Suspense fallback={<div></div>}>
              <LayoutSectionByType />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/header/:id`}
          element={
            <Suspense fallback={<div></div>}>
              <EditLayoutSection />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/content_left/:id`}
          element={
            <Suspense fallback={<div></div>}>
              <EditLayoutSection />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/content_right/:id`}
          element={
            <Suspense fallback={<div></div>}>
              <EditLayoutSection />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/footer/:id`}
          element={
            <Suspense fallback={<div></div>}>
              <EditLayoutSection />
            </Suspense>
          }
        />
        <Route
          path={AdminPaths.ROUTES}
          element={
            <Suspense fallback={<div></div>}>
              <Pages />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.ROUTES}/:p`}
          element={
            <Suspense fallback={<div></div>}>
              <PageById />
            </Suspense>
          }
        />
        <Route
          path={AdminPaths.BASIC_COMPONENTS}
          element={
            <Suspense fallback={<div></div>}>
              <Components />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/slide-shows`}
          element={
            <Suspense fallback={<div></div>}>
              <BasicComponents type={BasicComponentTypes.SLIDESHOW} />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/cards`}
          element={
            <Suspense fallback={<div></div>}>
              <BasicComponents type={BasicComponentTypes.CARD} />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/faqs`}
          element={
            <Suspense fallback={<div></div>}>
              <BasicComponents type={BasicComponentTypes.FAQ} />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/dropdowns`}
          element={
            <Suspense fallback={<div></div>}>
              <BasicComponents type={BasicComponentTypes.DROPDOWN} />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/templates`}
          element={
            <Suspense fallback={<div></div>}>
              <Templates />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/templates/:p`}
          element={
            <Suspense fallback={<div></div>}>
              <TemplateComponentById />
            </Suspense>
          }
        />
        <Route
          path={AdminPaths.HOCS}
          element={
            <Suspense fallback={<div></div>}>
              <HocComponents />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.HOCS}/items`}
          element={
            <Suspense fallback={<div></div>}>
              <HighOrderComponents />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.HOCS}/templates`}
          element={
            <Suspense fallback={<div></div>}>
              <HocTemplates />
            </Suspense>
          }
        />
        <Route
          path={`${AdminPaths.HOCS}/templates/:p`}
          element={
            <Suspense fallback={<div></div>}>
              <TemplateHocById />
            </Suspense>
          }
        />
        <Route
          path={AdminPaths.MEDIA}
          element={
            <Suspense fallback={<div></div>}>
              <Media />
            </Suspense>
          }
        />
        <Route
          path={AdminPaths.SETTINGS}
          element={
            <Suspense fallback={<div></div>}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<div></div>}>
              <PageNotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default Routing;
