import classes from "./cookies.module.css";
import { useTranslation } from "react-i18next";
import Button, { ButtonClassTypes } from "../ui/button/Button";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { CookiePolicyPageIdentifier } from "../../firebase/types";

export function Cookies({ handleClick }: { handleClick: () => any }) {
  const { t } = useTranslation();
  const { isMobileDevice } = useAppContext();

  return (
    <div className={classes.Outer} id="non-printable">
      <p className="PaddingB">
        {t("cookies_expl_1")}
        <Link to={`/page/${CookiePolicyPageIdentifier}`}>
          <span className={classes.CookieLink}>{t("cookies_policy")}</span>
        </Link>
      </p>
      <Button
        className={ButtonClassTypes.YELLOW}
        handleClick={handleClick}
        fullWidth={isMobileDevice}
      >
        {t("understood")}
      </Button>
    </div>
  );
}
