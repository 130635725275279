import { useTranslation } from "react-i18next";
import classes from "./SaveCancelButtons.module.css";
import Button, { ButtonClassTypes } from "../ui/button/Button";
import { motion } from "framer-motion";

interface PropsType {
  handleClickSave: () => any;
  handleClickCancel: () => any;
}

function SaveCancelButtons({ handleClickSave, handleClickCancel }: PropsType) {
  const { t } = useTranslation();

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={classes.Outer}
    >
      <Button
        className={ButtonClassTypes.GREEN}
        handleClick={handleClickSave}
        fullWidth={true}
      >
        {t("save")}
      </Button>
      <Button
        className={ButtonClassTypes.YELLOW}
        handleClick={handleClickCancel}
        fullWidth={true}
      >
        {t("cancel")}
      </Button>
    </motion.div>
  );
}

export default SaveCancelButtons;
