import { Navigate, useLocation } from "react-router-dom";

function RequireAuth({ children, user }: { children: JSX.Element; user: any }) {
  const location = useLocation();
  const data = localStorage.getItem("loggedAs");

  if (!user && data === "-") {
    return <Navigate to="/authenticate" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
