import { useEffect, useState } from "react";
import { Cookies } from "../components/cookies/Cookies";
import { ContextProvider } from "../context/AppContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Aux from "./auxiliary";

import Routing from "./Routing";

function App() {
  const [cookies, setCookies] = useState("all");
  useEffect(() => {
    const savedCookies = localStorage.getItem("user_accepted_cookies");
    if (savedCookies !== "all") {
      setCookies("");
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("user_accepted_cookies", "all");
    setCookies("all");
  };

  return (
    <Aux>
      <ContextProvider>
        <DndProvider backend={HTML5Backend}>
          <Routing />
          {cookies !== "all" && <Cookies handleClick={acceptCookies} />}
        </DndProvider>
      </ContextProvider>
    </Aux>
  );
}

export default App;
