import { useCallback, useMemo, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import {
  BasicComponentType,
  BasicComponentTypes,
  PageStructure,
  WrapperComponentType,
} from "../../../firebase/types";
import { PAGE_CONTENT_KEY } from "../../admin/PageById";
import CardsComponent from "../../../components/basic-components/CardsComponent";
import FaqComponent from "../../../components/basic-components/FaqComponent";
import SlideShowComponent from "../../../components/slide-show/SlideShowComponent";
import WrapperComponent from "../../../components/wrappers-hoc/WrapperComponent";
import { parseJsonStringifiedToJsonObject } from "../../../helper-functions/general";
import StyledDivOrAux from "../../../hoc/StyledDivOrAux";
import DropdownComponent from "../../../components/basic-components/DropdownComponent";

function DyPaStructureElement({
  item,
  content,
}: {
  item: PageStructure;
  content?: any;
}) {
  const { basicComponents, wrapperComponents, isMobileDevice } =
    useAppContext();
  const [isHoveringOuter, setIsHoveringOuter] = useState(false);
  const [isHoveringInner, setIsHoveringInner] = useState(false);

  const isTextEditor =
    item.id.indexOf(PAGE_CONTENT_KEY) === 0 && content ? true : false;

  const foundCard = useMemo(
    () =>
      basicComponents
        .filter(
          (bc: BasicComponentType) => bc.type === BasicComponentTypes.CARD
        )
        .find((c: BasicComponentType) => c.id && item.id.includes(c.id)),
    [basicComponents, item]
  );
  const foundDropdown = useMemo(
    () =>
      basicComponents
        .filter(
          (bc: BasicComponentType) => bc.type === BasicComponentTypes.DROPDOWN
        )
        .find((c: BasicComponentType) => c.id && item.id.includes(c.id)),
    [basicComponents, item]
  );
  const foundFaq = useMemo(
    () =>
      basicComponents
        .filter((bc: BasicComponentType) => bc.type === BasicComponentTypes.FAQ)
        .find((c: BasicComponentType) => c.id && item.id.includes(c.id)),
    [basicComponents, item]
  );
  const foundSlideshow = useMemo(
    () =>
      basicComponents
        .filter(
          (bc: BasicComponentType) => bc.type === BasicComponentTypes.SLIDESHOW
        )
        .find((s: BasicComponentType) => s.id && item.id.includes(s.id)),
    [basicComponents, item]
  );
  const foundHoc = useMemo(
    () =>
      wrapperComponents.find(
        (s: WrapperComponentType) => s.id && item.id.includes(s.id)
      ),
    [wrapperComponents, item]
  );

  const outerStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? item.styleOuterM : item.styleOuter
      ),
    [isMobileDevice, item]
  );
  const outerHoverStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? "" : item.styleOuterHover
      ),
    [isMobileDevice, item]
  );
  const innerStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? item.styleInnerM : item.styleInner
      ),
    [isMobileDevice, item]
  );
  const innerHoverStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? "" : item.styleInnerHover
      ),
    [isMobileDevice, item]
  );

  const shouldHoverOuter = useMemo(
    () => Object.keys(outerHoverStyle).length > 0,
    [outerHoverStyle]
  );
  const shouldHoverInner = useMemo(
    () => Object.keys(innerHoverStyle).length > 0,
    [innerHoverStyle]
  );
  const toggleIsHoveringOuter = useCallback(
    (val: boolean) => shouldHoverOuter && setIsHoveringOuter(val),
    [shouldHoverOuter]
  );
  const toggleIsHoveringInner = useCallback(
    (val: boolean) => shouldHoverInner && setIsHoveringInner(val),
    [shouldHoverInner]
  );

  return (
    <StyledDivOrAux
      styleObj={isHoveringOuter ? outerHoverStyle : outerStyle}
      onMouseEnter={() => toggleIsHoveringOuter(true)}
      onMouseLeave={() => toggleIsHoveringOuter(false)}
    >
      {isTextEditor ? (
        <StyledDivOrAux
          styleObj={isHoveringInner ? innerHoverStyle : innerStyle}
          onMouseEnter={() => toggleIsHoveringInner(true)}
          onMouseLeave={() => toggleIsHoveringInner(false)}
          innerHTMLContent={
            content && content.hasOwnProperty(item.id) ? content[item.id] : ""
          }
        ></StyledDivOrAux>
      ) : (
        <StyledDivOrAux
          styleObj={isHoveringInner ? innerHoverStyle : innerStyle}
          onMouseEnter={() => toggleIsHoveringInner(true)}
          onMouseLeave={() => toggleIsHoveringInner(false)}
        >
          {foundCard && <CardsComponent card={foundCard} />}

          {foundDropdown && <DropdownComponent dropdown={foundDropdown} />}

          {foundFaq && <FaqComponent faq={foundFaq} />}

          {foundSlideshow && <SlideShowComponent slideShow={foundSlideshow} />}

          {foundHoc && <WrapperComponent item={foundHoc} />}
        </StyledDivOrAux>
      )}
    </StyledDivOrAux>
  );
}

export default DyPaStructureElement;
