import classes from "./input.module.css";
import { CSSProperties } from "react";

export const enum InputThemeOptions {
  LIGHT,
  DARK,
}

export interface InputType {
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  type?: "text" | "password" | "number";
  textarea?: boolean;
  rows?: number;
  cols?: number;
  styleOuter?: CSSProperties;
  styleLabel?: CSSProperties;
  styleInput?: CSSProperties;
  error?: string;
  disabled?: boolean;
  handleChange: any;
  handleBlur?: any;
  inputTheme?: InputThemeOptions;
}

function Input({
  label,
  value,
  name,
  placeholder = "...",
  type,
  textarea,
  rows = 4,
  cols,
  styleOuter,
  styleLabel,
  styleInput,
  error,
  disabled,
  handleChange,
  handleBlur,
  inputTheme = InputThemeOptions.LIGHT,
}: InputType) {
  const typeVal = type ? type : "text";
  const parseInputStyle = () => {
    let style = {};
    if (styleInput) style = styleInput;
    if (error) style = { ...style, border: "1px solid var(--text-error)" };
    return style;
  };

  return (
    <div style={styleOuter}>
      <label className={classes.Label} style={styleLabel}>
        {label}
      </label>
      {textarea ? (
        <textarea
          className={
            inputTheme === InputThemeOptions.DARK
              ? classes.InputDark
              : classes.Input
          }
          value={value}
          name={name}
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          style={parseInputStyle()}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      ) : (
        <input
          className={
            inputTheme === InputThemeOptions.LIGHT
              ? classes.Input
              : classes.InputDark
          }
          value={value}
          name={name}
          placeholder={placeholder}
          type={typeVal}
          style={parseInputStyle()}
          disabled={disabled}
          onChange={handleChange}
        />
      )}
      {error && <p className={classes.Error}>{error}</p>}
    </div>
  );
}

export default Input;
