import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./hoc/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

//service worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// import i18n (needs to be bundled ;))
import "./hoc/i18nextConf";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // STRICT MODE asta face ca totul sa se incarce de 2 ori.
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();
