import classes from "./checkbox.module.css";
import ProcessedClassName from "../helper-functions";

interface PropsType {
  checked?: boolean;
  className?: string;
  title?: string;
  label?: string;
  error?: string;
  errorPath?: string;
  extraUpdate?: any;
  handleChange: any;
  disabled?: boolean;
  name?: string;
}

const Checkbox = (props: PropsType) => {
  const {
    checked,
    className,
    title,
    label,
    error,
    errorPath,
    extraUpdate,
    handleChange,
    disabled,
    name,
  } = props;
  return (
    <div
      className={ProcessedClassName(classes, className)}
      style={{ display: "flex" }}
      title={title}
    >
      <div
        className={classes.Inner}
        onClick={(ev) => {
          if (disabled) return;
          handleChange(
            ev,
            { name, value: !checked, checked: !checked },
            errorPath,
            extraUpdate
          );
        }}
      >
        <div
          className={[
            disabled ? classes.CheckboxDisabled : classes.Checkbox,
            checked ? classes.Checked : null,
          ].join(" ")}
        >
          &nbsp;
        </div>
        {label ? (
          <span
            className={
              disabled
                ? classes.LabelDisabled
                : classes.Label + (error ? " " + classes.LabelError : "")
            }
          >
            {label}
          </span>
        ) : null}
      </div>
      {error ? <div className={classes.Error}>{error}</div> : null}
    </div>
  );
};

export default Checkbox;
