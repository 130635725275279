import { TranslationMonthsArray } from "../firebase/types";

export const dateFormatToDayMonthYear = (date: number | undefined, t: any) => {
  if (!date || !t) return " - ";

  const months = t("month_mmmm", {
    returnObjects: true,
  });
  const dateObj = new Date(date);
  const month = months.find(
    (m: TranslationMonthsArray) => m.value === dateObj.getMonth()
  )?.label;
  return dateObj.getDate() + " " + month + " " + dateObj.getFullYear();
};

export const shortDateFormatToDayMonthYear = (
  date: number | undefined,
  t: any
) => {
  if (!date || !t) return " - ";

  const months = t("month_mmmm", {
    returnObjects: true,
  });
  const dateObj = new Date(date);
  const month = months
    .find((m: TranslationMonthsArray) => m.value === dateObj.getMonth())
    ?.label.substring(0, 3);
  return dateObj.getDate() + " " + month + " " + dateObj.getFullYear();
};

export const shortDateFormatWithTime = (date: number | undefined, t: any) => {
  if (!date || !t) return " - ";

  const months = t("month_mmmm", {
    returnObjects: true,
  });
  const dateObj = new Date(date);
  const month = months
    .find((m: TranslationMonthsArray) => m.value === dateObj.getMonth())
    ?.label.substring(0, 3);
  let result = dateObj.getDate() + " " + month + " " + dateObj.getFullYear();

  result += " " + t("at").toLowerCase() + " ";
  result += (dateObj.getHours() < 10 ? "0" : "") + dateObj.getHours() + ":";
  result += (dateObj.getMinutes() < 10 ? "0" : "") + dateObj.getMinutes();
  result += dateObj.getHours() < 12 ? " A.M." : " P.M.";

  return result;
};

export const dateFormatWithTime = (date: number | undefined, t: any) => {
  if (!date || !t) return " - ";

  const months = t("month_mmmm", {
    returnObjects: true,
  });
  const dateObj = new Date(date);
  const month = months.find(
    (m: TranslationMonthsArray) => m.value === dateObj.getMonth()
  )?.label;
  let result = dateObj.getDate() + " " + month + " " + dateObj.getFullYear();

  result += " " + t("at").toLowerCase() + " ";
  result += (dateObj.getHours() < 10 ? "0" : "") + dateObj.getHours() + ":";
  result += (dateObj.getMinutes() < 10 ? "0" : "") + dateObj.getMinutes();
  result += dateObj.getHours() < 12 ? " A.M." : " P.M.";

  return result;
};
