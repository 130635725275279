import { useMemo, useState } from "react";
import classes from "./button.module.css";

export const enum ButtonClassTypes {
  BLUE = "Blue",
  GREEN = "Green",
  YELLOW = "Yellow",
  RED = "Red",
  TWIN = "Twin",
}
export const enum ButtonTypeTypes {
  BUTTON = "button",
  SUBMIT = "submit",
  reset = "reset",
}

interface ButtonType {
  type?: ButtonTypeTypes;
  className?: ButtonClassTypes;
  disabled?: boolean;
  handleClick: any;
  children: any;
  style?: any;
  fullWidth?: boolean;
}

const Button = ({
  type = ButtonTypeTypes.BUTTON,
  className,
  disabled = false,
  handleClick,
  children,
  style,
  fullWidth,
}: ButtonType) => {
  const [classVal, setClassVal] = useState<any>(classes.CustButtOuter);

  useMemo(() => {
    const parseClassName = (className: ButtonClassTypes) => {
      let result = [classes.CustButtOuter];
      if (className && typeof className === "string" && classes[className]) {
        result.push(classes[className]);
      }
      setClassVal(result.join(" "));
    };
    if (className) parseClassName(className);
  }, [className]);

  return (
    <div className={classVal} style={style}>
      <button
        className={
          classes.CustButt + (fullWidth ? " " + classes.FullWidth : "")
        }
        disabled={disabled}
        onClick={handleClick}
        type={type}
      >
        <span className={classes.CustButtSpan}>{children}</span>
      </button>
    </div>
  );
};

export default Button;
