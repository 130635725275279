// import classes from "./cards-component.module.css";
import { useAppContext } from "../../context/AppContext";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { parseJsonStringifiedToJsonObject } from "../../helper-functions/general";
import {
  BasicComponentType,
  BasicComponentTypes,
  WrapperComponentType,
} from "../../firebase/types";
import CardsComponent from "../basic-components/CardsComponent";
import FaqComponent from "../basic-components/FaqComponent";
import SlideShowComponent from "../slide-show/SlideShowComponent";
import StyledDivOrAux from "../../hoc/StyledDivOrAux";
import DropdownComponent from "../basic-components/DropdownComponent";

function WrapperComponent({
  item,
}: {
  item: WrapperComponentType;
}): ReactElement {
  const { isMobileDevice, basicComponents, wrapperComponents } =
    useAppContext();
  const [neededComponents, setNeededComponents] = useState<any[]>([]);

  const [isHoveringOuter, setIsHoveringOuter] = useState(false);
  const [isHoveringInner, setIsHoveringInner] = useState(false);

  const outerStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? item.styleOuterM : item.styleOuter
      ),
    [isMobileDevice, item]
  );
  const outerHoverStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? "" : item.styleOuterHover
      ),
    [isMobileDevice, item]
  );
  const innerStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? item.styleInnerM : item.styleInner
      ),
    [isMobileDevice, item]
  );
  const innerHoverStyle = useMemo(
    () =>
      parseJsonStringifiedToJsonObject(
        isMobileDevice ? "" : item.styleInnerHover
      ),
    [isMobileDevice, item]
  );

  const shouldHoverOuter = useMemo(
    () => Object.keys(outerHoverStyle).length > 0,
    [outerHoverStyle]
  );
  const shouldHoverInner = useMemo(
    () => Object.keys(innerHoverStyle).length > 0,
    [innerHoverStyle]
  );
  const toggleIsHoveringOuter = useCallback(
    (val: boolean) => shouldHoverOuter && setIsHoveringOuter(val),
    [shouldHoverOuter]
  );
  const toggleIsHoveringInner = useCallback(
    (val: boolean) => shouldHoverInner && setIsHoveringInner(val),
    [shouldHoverInner]
  );

  useEffect(() => {
    const parse_needed = () => {
      const needed: any[] = [];
      item.childrenIDs?.forEach((id: string) => {
        const found = basicComponents.find(
          (bc: BasicComponentType) => bc.id === id
        );
        if (found) needed.push(found);
      });
      item.childrenIDs?.forEach((id: string) => {
        const found = wrapperComponents.find(
          (wc: WrapperComponentType) => wc.id === id
        );
        if (found) needed.push(found);
      });

      setNeededComponents(needed);
    };

    if (basicComponents && wrapperComponents && item && item.childrenIDs) {
      parse_needed();
    }
  }, [basicComponents, wrapperComponents, item]);

  const renderBcCard = useCallback(
    (bc: BasicComponentType, idx: number) => {
      return (
        <CardsComponent
          card={bc}
          key={idx}
          toggleSideDrawer={item?.isSideMenu}
        />
      );
    },
    [item]
  );
  const renderBcDropdown = useCallback(
    (bc: BasicComponentType, idx: number) => {
      return <DropdownComponent dropdown={bc} key={idx} />;
    },
    []
  );
  const renderBcFaq = useCallback((bc: BasicComponentType, idx: number) => {
    return <FaqComponent faq={bc} key={idx} />;
  }, []);
  const renderBcSlideshow = useCallback(
    (bc: BasicComponentType, idx: number) => {
      return <SlideShowComponent slideShow={bc} key={idx} />;
    },
    []
  );

  if (!item) return <div></div>;

  return (
    <StyledDivOrAux
      styleObj={isHoveringOuter ? outerHoverStyle : outerStyle}
      onMouseEnter={() => toggleIsHoveringOuter(true)}
      onMouseLeave={() => toggleIsHoveringOuter(false)}
    >
      <StyledDivOrAux
        styleObj={isHoveringInner ? innerHoverStyle : innerStyle}
        onMouseEnter={() => toggleIsHoveringInner(true)}
        onMouseLeave={() => toggleIsHoveringInner(false)}
      >
        {neededComponents &&
          neededComponents.map((bc: any, idx: number) => {
            if (bc && bc.type) {
              if (bc.type === BasicComponentTypes.CARD) {
                return renderBcCard(bc, idx);
              }
              if (bc.type === BasicComponentTypes.DROPDOWN) {
                return renderBcDropdown(bc, idx);
              }
              if (bc.type === BasicComponentTypes.FAQ) {
                return renderBcFaq(bc, idx);
              }
              if (bc.type === BasicComponentTypes.SLIDESHOW) {
                return renderBcSlideshow(bc, idx);
              }
            }
            return <WrapperComponent item={bc} key={idx} />;
          })}
      </StyledDivOrAux>
    </StyledDivOrAux>
  );
}

export default WrapperComponent;
