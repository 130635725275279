import { useTranslation } from "react-i18next";
import classes from "./admin-classes.module.css";
import { useAppContext } from "../../context/AppContext";
import { dateFormatToDayMonthYear } from "../../helper-functions/date-time";
import { motion } from "framer-motion";

function Landing() {
  const { t } = useTranslation();
  const { currentUser } = useAppContext();

  const createdAt = currentUser?.metadata?.createdAt
    ? dateFormatToDayMonthYear(currentUser.metadata.createdAt * 1, t)
    : "-";
  const lastLoginAt = currentUser?.metadata?.lastLoginAt
    ? dateFormatToDayMonthYear(currentUser.metadata.lastLoginAt * 1, t)
    : "-";

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <p className="RouteTitle">{t("user_details")}</p>
      <br />

      <p className="Explanation">{t("email")}:</p>
      <p className={classes.UserDetail}>
        {currentUser?.email ? currentUser.email : "-"}
      </p>

      <p className="Explanation">{t("created_at")}:</p>
      <p className={classes.UserDetail}>{createdAt}</p>

      <p className="Explanation">{t("last_login_at")}:</p>
      <p className={classes.UserDetail}>{lastLoginAt}</p>
    </motion.div>
  );
}

export default Landing;
