import { useAppContext } from "../../context/AppContext";
import {
  BasicComponentDataType,
  BasicComponentType,
} from "../../firebase/types";
import { parseJsonStringifiedToJsonObject } from "../../helper-functions/general";

function BasicComponentDataText(
  className: any,
  data: BasicComponentDataType,
  bc: BasicComponentType
) {
  const { language, isMobileDevice } = useAppContext();

  return data.text &&
    data.text.hasOwnProperty(language) &&
    data.text[language] !== "" ? (
    <div
      className={className}
      style={parseJsonStringifiedToJsonObject(
        isMobileDevice ? bc.styleTextM : bc.styleText
      )}
      dangerouslySetInnerHTML={{
        __html: data.text[language],
      }}
    ></div>
  ) : null;
}

export default BasicComponentDataText;
