import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import translationDE from "../assets/locales/de/translation.json";
import translationEN from "../assets/locales/en/translation.json";
import translationRO from "../assets/locales/ro/translation.json";
import translationES from "../assets/locales/es/translation.json";

const fallbackLng = "en";
const availableLanguages = ["de", "en", "ro", "es"];

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
  ro: {
    translation: translationRO,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
