import classes from "./popup-spinner.module.css";
import ErrorRedSvg from "../../assets/svg/error_red.svg";
import CheckSvg from "../../assets/svg/check.svg";
import Aux from "../auxiliary";
import SimpleLoader from "../../components/animations/simple-loader/SimpleLoader";

const closeSpinner = (props) => {
  if (props.showPopup !== "spinner") props.actionClose();
};

const PopupSpinner = (props) => {
  const {
    className,
    actionClose,
    style,
    hasHoverEffect,
    title,
    hideHeader,
    hideX,

    showPopup,
    messageProcess,
    messageSuccess,
    messageFail,
    children,
  } = props;

  let messageContent = "";
  switch (showPopup) {
    case "spinner":
      messageContent =
        messageProcess && messageProcess !== undefined ? messageProcess : "";
      break;
    case "spinner-success":
      messageContent =
        messageSuccess && messageSuccess !== undefined ? messageSuccess : "";
      break;
    case "spinner-fail":
      messageContent =
        messageFail && messageFail !== undefined ? messageFail : "";
      break;

    default:
      break;
  }

  return (
    <div
      className={[classes.Outer, classes[className]].join(" ")}
      onMouseDown={() => closeSpinner(props)}
    >
      <div
        style={style}
        className={
          messageContent === "" ? classes.InnerOnlyAnimation : classes.Inner
        }
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        onMouseLeave={hasHoverEffect ? () => closeSpinner(props) : null}
      >
        {hideHeader ? null : (
          <div className={classes.Header}>
            <p className={classes.Title}>{title ? title : null}</p>
            {hideX ? null : (
              <p className={classes.X} onClick={() => closeSpinner(props)}>
                &#215;
              </p>
            )}
          </div>
        )}
        {hideHeader ? null : (
          <Aux>
            <br />
            <br />
          </Aux>
        )}
        <br />
        {/* show for: success */}
        <img
          className={`SpinnerSvgs${
            showPopup === "spinner-success" ? "" : " DisplayNone"
          }`}
          src={CheckSvg}
          alt="svg"
          onClick={actionClose}
        />
        {/* show for: fail */}
        <img
          className={showPopup === "spinner-fail" ? "" : " DisplayNone"}
          src={ErrorRedSvg}
          alt="svg"
          onClick={actionClose}
        />

        {/* animation: */}
        {showPopup === "spinner" && <SimpleLoader title={title} />}

        {messageContent !== "" && (
          <p className={classes.SpinnerMessage}>{messageContent}</p>
        )}
        {children}
      </div>
    </div>
  );
};

export default PopupSpinner;
