import { CSSProperties, ReactNode, useMemo } from "react";
import Aux from "./auxiliary";

interface SDOAProps {
  styleObj?: CSSProperties;
  children?: ReactNode;
  onMouseEnter?: any;
  onMouseLeave?: any;
  innerHTMLContent?: string;
}

function StyledDivOrAux({
  styleObj,
  children,
  onMouseEnter,
  onMouseLeave,
  innerHTMLContent,
}: SDOAProps) {
  const handleMouseEnter = useMemo(
    () => (onMouseEnter ? onMouseEnter : () => {}),
    [onMouseEnter]
  );
  const handleMouseLeave = useMemo(
    () => (onMouseLeave ? onMouseLeave : () => {}),
    [onMouseLeave]
  );

  const returnedNode: ReactNode = useMemo(
    () =>
      innerHTMLContent ? (
        <div
          style={styleObj}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          dangerouslySetInnerHTML={{
            __html: innerHTMLContent,
          }}
        >
          {children}
        </div>
      ) : styleObj && Object.keys(styleObj).length > 0 ? (
        <div
          style={styleObj}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
      ) : (
        <Aux>{children}</Aux>
      ),
    [styleObj, children, handleMouseEnter, handleMouseLeave, innerHTMLContent]
  );

  return returnedNode;
}

export default StyledDivOrAux;
