import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import {
  CookiePolicyPageIdentifier,
  HomePageIdentifier,
  LayoutSectionTypes,
  PageType,
} from "../../../firebase/types";
import Aux from "../../../hoc/auxiliary";
import SimpleLoader from "../../../components/animations/simple-loader/SimpleLoader";
import LayoutWrapper from "../../../components/admin-pages/LayoutWrapper";
import LayoutComponent from "../../../components/admin-pages/LayoutComponent";
import { LayoutSectionAuxOptions } from "../../../components/admin-pages/SelectLayoutSection";

function DynamicPage({
  homePage,
  outletPath,
}: {
  homePage?: boolean;
  outletPath?: string;
}) {
  const { id } = useParams();
  const { get_page_by_path, language } = useAppContext();
  const [page, setPage] = useState<PageType>();

  useEffect(() => {
    const getPageDetails = async () => {
      let pagePath = "";
      if (homePage) {
        pagePath = HomePageIdentifier;
      } else if (id && id === CookiePolicyPageIdentifier) {
        pagePath = CookiePolicyPageIdentifier;
      } else if (id) {
        pagePath = id;
      }

      if (outletPath) pagePath = outletPath;

      const pageDb = await get_page_by_path(pagePath);
      if (pageDb) {
        setPage(pageDb);
      }
    };

    if (id || homePage) getPageDetails();
  }, [id, homePage, outletPath, get_page_by_path]);

  const content = useMemo(() => {
    if (page && language) {
      const foundData = page.data.find((d: any) => d.language === language);
      if (foundData) {
        return foundData.content ? foundData.content : { key: "" };
      }
    }
  }, [page, language]);

  const pageHeaderSetting = useMemo(
    () => page?.layoutStructure?.header,
    [page]
  );
  const pageCLSetting = useMemo(
    () => page?.layoutStructure?.content_left,
    [page]
  );
  const pageCRSetting = useMemo(
    () => page?.layoutStructure?.content_right,
    [page]
  );
  const pageFooterSetting = useMemo(
    () => page?.layoutStructure?.footer,
    [page]
  );

  if (!page)
    return (
      <div className="ClientLoader">
        <SimpleLoader />
      </div>
    );

  return (
    <Aux>
      {pageHeaderSetting !== LayoutSectionAuxOptions.DONT_USE && (
        <LayoutComponent
          pageLayoutStructure={page?.layoutStructure}
          content={content}
          type={LayoutSectionTypes.HEADER}
        />
      )}

      <LayoutWrapper type={LayoutSectionTypes.MIDDLE}>
        {pageCLSetting !== LayoutSectionAuxOptions.DONT_USE && (
          <LayoutComponent
            pageLayoutStructure={page?.layoutStructure}
            content={content}
            type={LayoutSectionTypes.CONTENT_LEFT}
          />
        )}

        <LayoutComponent
          pageLayoutStructure={page?.layoutStructure}
          content={content}
          type={LayoutSectionTypes.ROUTES}
          page={page}
        />

        {pageCRSetting !== LayoutSectionAuxOptions.DONT_USE && (
          <LayoutComponent
            pageLayoutStructure={page?.layoutStructure}
            content={content}
            type={LayoutSectionTypes.CONTENT_RIGHT}
          />
        )}
      </LayoutWrapper>

      {pageFooterSetting !== LayoutSectionAuxOptions.DONT_USE && (
        <LayoutComponent
          pageLayoutStructure={page?.layoutStructure}
          content={content}
          type={LayoutSectionTypes.FOOTER}
        />
      )}
    </Aux>
  );
}

export default DynamicPage;
