import { lazy, Suspense } from "react";
const TextEditorLazyLoaded = lazy(() => import("./TextEditorLazyLoaded"));

const TextEditor = ({ handleBlur, value, restrictButtons = false }) => (
  <Suspense fallback={<p></p>}>
    <TextEditorLazyLoaded
      restrictButtons={restrictButtons}
      handleBlur={handleBlur}
      value={value}
    />
  </Suspense>
);

export default TextEditor;
