import classes from "./SimpleLoader.module.css";

function SimpleLoader({ title }: { title?: string }) {
  return (
    <div className={classes.Card} title={title ? title : ""}>
      <div className={classes.Loader}>
        <div className={classes.Dash}></div>
        <div className={classes.Marks}>
          <div className={classes.LeftMark}></div>
          <div className={classes.RightMark}></div>
        </div>
      </div>
    </div>
  );
}

export default SimpleLoader;
