const processedClassName = (classes, className) => {
  let clsName = [classes.Outer];

  if (className && typeof className === "string") {
    className.split(" ").forEach((cls) => {
      classes.hasOwnProperty(cls)
        ? clsName.push(classes[cls])
        : clsName.push(cls);
    });
  } else if (className !== undefined) {
    clsName.push(className);
  }

  return clsName.join(" ");
};

export default processedClassName;
