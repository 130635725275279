import { useTranslation } from "react-i18next";
import Select, { SelectOptionType } from "../ui/select/Select";
import { useAppContext } from "../../context/AppContext";

function LanguageSelect() {
  const { t } = useTranslation();
  const { language, change_language } = useAppContext();
  const languages: SelectOptionType[] = t("languages", { returnObjects: true });

  const changeLang = (data: any) => change_language(data.value);

  return (
    <Select
      selectOptions={{
        initialValue: language,
        handleChange: changeLang,
        options: languages,
        overallWidth: "calc(var(--admin-side-menu-width) - 32px)",
      }}
    />
  );
}

export default LanguageSelect;
