import classes from "./side-menu-wraper.module.css";
import { useAppContext } from "../../../context/AppContext";

function MenuToggle() {
  const { toggle_side_drawer } = useAppContext();

  return (
    <div className={classes.MenuToggle} onClick={toggle_side_drawer}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default MenuToggle;
