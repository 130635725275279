import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { PageType } from "../../firebase/types";

const LinkLabel = ({ page }: { page: PageType }): any => {
  const { t } = useTranslation();
  const { language } = useAppContext();

  const data = useMemo(
    () => page?.data.find((d: any) => d.language === language),
    [page, language]
  );

  return data?.linkLabel ? (
    data.linkLabel
  ) : (
    <span className="Explanation">{t("missing_translation")}</span>
  );
};

export default LinkLabel;
