import { CSSProperties, ReactNode, useMemo } from "react";
import { useAppContext } from "../../context/AppContext";
import { LayoutSectionTypes } from "../../firebase/types";
import { parseJsonStringifiedToJsonObject } from "../../helper-functions/general";
import StyledDivOrAux from "../../hoc/StyledDivOrAux";

interface LayoutWrapperType {
  type?: string;
  children?: ReactNode;
}

function LayoutWrapper({ type, children }: LayoutWrapperType) {
  const { settings, isMobileDevice } = useAppContext();

  let styleObj = useMemo(() => {
    let result: CSSProperties = {};
    if (!settings || !settings.layoutStyles) return result;

    let forMobile = "",
      forDesktop = "";

    switch (type) {
      case LayoutSectionTypes.HEADER:
        forMobile = settings.layoutStyles.styleHeaderM;
        forDesktop = settings.layoutStyles.styleHeader;
        break;
      case LayoutSectionTypes.MIDDLE:
        forMobile = settings.layoutStyles.styleMiddleM;
        forDesktop = settings.layoutStyles.styleMiddle;
        break;
      case LayoutSectionTypes.ROUTES:
        forMobile = settings.layoutStyles.stylestyleColumn2M;
        forDesktop = settings.layoutStyles.styleColumn2;
        break;
      case LayoutSectionTypes.CONTENT_LEFT:
        forMobile = settings.layoutStyles.stylestyleColumn1M;
        forDesktop = settings.layoutStyles.styleColumn1;
        break;
      case LayoutSectionTypes.FOOTER:
        forMobile = settings.layoutStyles.styleFooterM;
        forDesktop = settings.layoutStyles.styleFooter;
        break;
      case LayoutSectionTypes.CONTENT_RIGHT:
        forMobile = settings.layoutStyles.stylestyleColumn3M;
        forDesktop = settings.layoutStyles.styleColumn3;
        break;

      default:
        break;
    }
    result = parseJsonStringifiedToJsonObject(
      isMobileDevice ? forMobile : forDesktop
    );

    return result;
  }, [settings, type, isMobileDevice]);

  return <StyledDivOrAux styleObj={styleObj}>{children}</StyledDivOrAux>;
}

export default LayoutWrapper;
