import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import {
  LayoutComponentShowTypes,
  LayoutSectionType,
  LayoutSectionTypes,
  LayoutStructureType,
  PageStructure,
  PageType,
} from "../../firebase/types";
import { findDefaultLayoutSectionPerType } from "../../helper-functions/general";
import Aux from "../../hoc/auxiliary";
import DyPaStructureElement from "../../routes/public/dynamic-pages/DyPaStructureElement";
import LayoutWrapper from "./LayoutWrapper";

interface LayoutComponentType {
  pageLayoutStructure?: LayoutStructureType;
  content?: any;
  type?: LayoutSectionTypes;
  page?: PageType;
  showType?: LayoutComponentShowTypes;
  useEditElement?: LayoutSectionType;
}

function LayoutComponent({
  pageLayoutStructure,
  content,
  type,
  page,
  showType,
  useEditElement,
}: LayoutComponentType) {
  const { t } = useTranslation();
  const { layoutSections } = useAppContext();

  const layout = useMemo(() => {
    let result: LayoutSectionType | undefined;
    if (!type) return result;

    if (
      pageLayoutStructure &&
      (!showType || showType === LayoutComponentShowTypes.USE_ALL_OPTIONS)
    ) {
      let id = "";
      switch (type) {
        case LayoutSectionTypes.HEADER:
          id = pageLayoutStructure.header ? pageLayoutStructure.header : "";
          break;
        case LayoutSectionTypes.CONTENT_LEFT:
          id = pageLayoutStructure.content_left
            ? pageLayoutStructure.content_left
            : "";
          break;
        case LayoutSectionTypes.CONTENT_RIGHT:
          id = pageLayoutStructure.content_right
            ? pageLayoutStructure.content_right
            : "";
          break;
        case LayoutSectionTypes.FOOTER:
          id = pageLayoutStructure.footer ? pageLayoutStructure.footer : "";
          break;

        default:
          break;
      }
      const found = layoutSections.find(
        (ls: LayoutSectionType) => ls.id === id
      );
      result = found;
    }

    if (useEditElement) {
      return useEditElement;
    }

    if (!result) {
      result = findDefaultLayoutSectionPerType(layoutSections, type);
    }

    return result;
  }, [layoutSections, pageLayoutStructure, type, showType, useEditElement]);

  return (
    <Aux>
      {showType && showType !== LayoutComponentShowTypes.USE_ALL_OPTIONS ? (
        <Aux>
          <br />
          <p className="RouteTitle">{t("preview")}</p>
          <p className="Explanation">
            {t("this_is_the_default_element_for_this_layout_section")}
          </p>
          <br />
        </Aux>
      ) : null}

      {layout && layout.structure ? (
        <Aux>
          <LayoutWrapper type={type}>
            {layout.structure.map((pS: PageStructure, idx: number) => (
              <DyPaStructureElement item={pS} content={content} key={idx} />
            ))}
          </LayoutWrapper>
        </Aux>
      ) : null}

      {!layout && page && page.structure && type ? (
        <LayoutWrapper type={type}>
          {page.structure.map((pS: PageStructure, idx: number) => (
            <DyPaStructureElement item={pS} content={content} key={idx} />
          ))}
        </LayoutWrapper>
      ) : null}
    </Aux>
  );
}

export default LayoutComponent;
