import classes from "./draggable.module.css";
import { CSSProperties, ReactNode, useMemo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { motion } from "framer-motion";

export const enum DraggableListTypes {
  PAGE,
  LAYOUT,
}
const ItemTypes = {
  ELEMENT: "element",
};
interface DragItem {
  index: number;
  id: string;
  type: string;
}
interface DLEWProps {
  showPreview?: string;
  id: string;
  index: number;
  moveElement: (dragIndex: number, hoverIndex: number) => void;
  children?: ReactNode;
  dragTitle?: ReactNode;
}

const dragStyle = {
  boxShadow: "var(--box-shadow-06)",
  backgroundColor: "white",
  margin: "17px 3px",
  width: "auto",
  borderRadius: "var(--border-radius-big)",
};

function DraggableListElementWrapper({
  showPreview,
  id,
  index,
  moveElement,
  children,
  dragTitle,
}: DLEWProps) {
  const liRef = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.ELEMENT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!liRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = liRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveElement(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ELEMENT,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(liRef));

  const styleOuter = useMemo(() => (isDragging ? dragStyle : {}), [isDragging]);
  const classMarker = useMemo(
    () =>
      isDragging
        ? [classes.DragHandle, classes.DragHandleOn].join(" ")
        : classes.DragHandle,
    [isDragging]
  );
  const styleMarker: CSSProperties = useMemo(
    () =>
      showPreview && showPreview === id
        ? {
            transition: "none",
            visibility: "hidden",
            padding: 0,
            width: 0,
          }
        : {},
    [showPreview, id]
  );

  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      data-handler-id={handlerId}
      style={styleOuter}
    >
      <span className={classMarker} style={styleMarker} ref={liRef}>
        &nbsp;
      </span>
      {isDragging ? (
        <div className={classes.WrapperDragging}>{dragTitle}</div>
      ) : (
        <div className={classes.WrapperStatic}>{children}</div>
      )}
    </motion.li>
  );
}

export default DraggableListElementWrapper;
