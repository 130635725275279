import classes from "./logo.module.css";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LogoInHeader() {
  const { t } = useTranslation();
  const { logoUrl } = useAppContext();
  let styleObj = { backgroundImage: `url(${logoUrl})` };
  const navigate = useNavigate();

  const openBaseUrl = () => {
    navigate("/");
  };

  return (
    <div
      className={classes.Outer}
      onClick={openBaseUrl}
      style={styleObj}
      title={t("go_to_the_homepage")}
    ></div>
  );
}

export default LogoInHeader;
